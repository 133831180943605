
var initialState = {
    permissions: {
        minified: [],
        fetchError: null
    }
};

export default function users(state = initialState, action = {}) {
    switch (action.type) {
        case "USER_PERMISSIONS_MINI_SUCCESS":
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    minified: action.payload,
                    fetchError: null
                }
            };

        case "USER_PERMISSIONS_MINI_FAILED":
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    fetchError: action.payload
                }
            };

        default:
            return {
                ...state
            };

    }
}