import React from 'react';

const UpdatedForm3 = (props) => {

  return (
    <div id="page3">
      <div
        style={{
          clear: "both",
          width: "100%",
          fontSize: "7pt",
          paddingTop: "4px",
          paddingBottom: "4px",
          borderTop: "2px solid black",
          borderBottom: "2px solid black"
        }}
      >
        <span>Form W-9 (Rev. 3-2024)</span>
        <span style={{ marginLeft: "620px" }}>Page{" "}<span style={{ fontSize: "10pt", fontWeight: "bold" }}> 3</span></span>
      </div>
      <div style={{ clear: "both", width: "100%", fontSize: "8pt" }}>
        <div style={{ float: "left", width: "380px" }}>
          <p>
            {" "}
            Certain payees and payments are exempt from backup withholding.
            See <em>Exempt payee code</em>, later, and the separate Instructions for the
            Requester of Form W-9 for more information.{" "}
          </p>
          <p> See also <em>Establishing U.S. status for purposes of chapter 3 and
            chapter 4 withholding</em>, earlier.</p>

          <p
            style={{
              marginTop: "4px",
              marginBottom: "0px",
              fontSize: "13pt",
              fontWeight: "bold"
            }}
          >
            What Is FATCA Reporting?
          </p>
          <p>
            {" "}
            The Foreign Account Tax Compliance Act (FATCA) requires a
            participating foreign financial institution to report all U.S. account
            holders that are specified U.S. persons. Certain payees are exempt from
            FATCA reporting. See <em>Exemption from FATCA reporting code</em>, later, and
            the Instructions for the Requester of Form W-9 for more information.
          </p>

          <p
            style={{
              marginTop: "4px",
              marginBottom: "0px",
              fontSize: "13pt",
              fontWeight: "bold"
            }}
          >
            Updating Your Information
          </p>
          <p>
            {" "}
            You must provide updated information to any person to whom you
            claimed to be an exempt payee if you are no longer an exempt payee
            and anticipate receiving reportable payments in the future from this
            person. For example, you may need to provide updated information if
            you are a C corporation that elects to be an S corporation, or if you are
            no longer tax exempt. In addition, you must furnish a new Form W-9 if
            the name or TIN changes for the account, for example, if the grantor of a
            grantor trust dies.
          </p>

          <p
            style={{
              marginTop: "4px",
              marginBottom: "0px",
              fontSize: "13pt",
              fontWeight: "bold"
            }}
          >
            Penalties
          </p>
          <p>
            {" "}
            <b>Failure to furnish TIN. </b> If you fail to furnish your correct TIN to a
            requester, you are subject to a penalty of $50 for each such failure
            unless your failure is due to reasonable cause and not to willful neglect.
          </p>
          <p>
            {" "}
            <b>
              Civil penalty for false information with respect to
              withholding.{" "}
            </b>{" "}
            If you make a false statement with no reasonable basis that
            results in no backup withholding, you are subject to a $500
            penalty.
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <b>Criminal penalty for falsifying information.</b> Willfully
            falsifying certifications or affirmations may subject you to
            criminal penalties including fines and/or imprisonment.
          </p>

          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <b>Misuse of TINs.</b> If the requester discloses or uses TINs
            in violation of federal law, the requester may be subject to
            civil and criminal penalties.
          </p>
          <p
            style={{
              marginTop: "7px",
              marginBottom: "0px",
              fontWeight: "bold",
              fontSize: "13pt"
            }}
          >
            Specific Instructions
          </p>
          <p style={{ fontSize: "10pt", fontWeight: "bold" }}> Line 1</p>
          <p>
            {" "}
            You must enter one of the following on this line;{" "}
            <b>do not</b> leave this line blank. The name should match the
            name on your tax return.
          </p>
          <p>
            {" "}
            If this Form W-9 is for a joint account (other than an account
            maintained by a foreign financial institution (FFI)), list
            first, and then circle, the name of the person or entity whose
            number you entered in Part I of Form W-9. If you are providing
            Form W-9 to an FFI to document a joint account, each holder of
            the account that is a U.S. person must provide a Form W-9.
          </p>

          <ol
            style={{
              margin: "2px 0px 0px 10px",
              padding: "0px",
              lineHeight: "14px",
              listStyle: "lower-alpha inside"
            }}
          >
            <li style={{ margin: "5px 0" }}>
              <b>Individual.</b> Generally, enter the name shown on your
              tax return. If you have changed your last name without
              informing the Social Security Administration (SSA) of the
              name change, enter your first name, the last name as shown
              on your social security card, and your new last name. <br />
              <b> Note for ITIN applicant:</b> Enter your individual name as it was entered
              on your Form W-7 application, line 1a. This should also be the same as
              the name you entered on the Form 1040 you filed with your application.
            </li>
            <li style={{ margin: "5px 0" }}>
              {" "}
              <b>Sole proprietor. </b> Enter your individual name as shown on your Form
              1040 on line 1. Enter your business, trade, or “doing business as” (DBA)
              name on line 2.
            </li>
            <li style={{ margin: "5px 0" }}>
              {" "}
              <b>
                Partnership, C corporation, S corporation, or LLC, other than a
                disregarded entity.
              </b>{" "}
              Enter the entity’s name as shown on the entity’s tax
              return on line 1 and any business, trade, or DBA name on line 2
            </li>
            <li style={{ margin: "5px 0" }}>
              {" "}
              <b> Other entities. </b> Enter your name as shown on required U.S. federal tax
              documents on line 1. This name should match the name shown on the
              charter or other legal document creating the entity. Enter any business,
              trade, or DBA name on line 2.
            </li>
            <li style={{ margin: "5px 0" }}>
              {" "}
              <b>Disregarded entity.</b> In general, a business entity that has a single
              owner, including an LLC, and is not a corporation, is disregarded as an
              entity separate from its owner (a disregarded entity). See Regulations
              section 301.7701-2(c)(2). A disregarded entity should check the
              appropriate box for the tax classification of its owner. Enter the owner’s
              name on line 1. The name of the owner entered on line 1 should never
              be a disregarded entity. The name on line 1 should be the name shown
              on the income tax return on which the income should be reported. For
            </li>
          </ol>
        </div>
        <div
          style={{ float: "left", width: "380px", marginLeft: "20px" }}
        >
          <p>example, if a foreign LLC that is treated as a disregarded entity for U.S.
            federal tax purposes has a single owner that is a U.S. person, the U.S.
            owner’s name is required to be provided on line 1. If the direct owner of
            the entity is also a disregarded entity, enter the first owner that is not
            disregarded for federal tax purposes. Enter the disregarded entity’s
            name on line 2. If the owner of the disregarded entity is a foreign person,
            the owner must complete an appropriate Form W-8 instead of a Form
            W-9. This is the case even if the foreign person has a U.S. TIN.</p>
          <p
            style={{
              fontSize: "10pt",
              fontWeight: "bold",
              margin: "4px 0"
            }}
          >
            {" "}
            Line 2
          </p>
          <p style={{ margin: "0px" }}>
            {" "}
            If you have a business name, trade name, DBA name, or disregarded
            entity name, enter it on line 2.
          </p>
          <p
            style={{
              fontSize: "10pt",
              fontWeight: "bold",
              margin: "4px 0"
            }}
          >
            {" "}
            Line 3a
          </p>
          <p style={{ margin: "0px" }}>
            {" "}
            Check the appropriate box on line 3a for the U.S. federal tax
            classification of the person whose name is entered on line 1. Check only
            one box on line 3a.
          </p>
          <table
            cellPadding={3}
            cellSpacing={0}
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              fontSize: "8pt",
              marginTop: "10px",
              width: "380px"
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top",
                    width: "189px"
                  }}
                >
                  IF the entity/individual on line 1 is <br /> a(n) . . .
                </td>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    fontWeight: "bold",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top",
                    width: "190px"
                  }}
                >
                  THEN check the box for . . .
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  • Corporation
                </td>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  Corporation
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  • Individual
                  <br />
                  • Sole proprietorship
                </td>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  Individual/sole proprietor
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  • LLC classified as a partnership
                  for U.S. federal tax purposes or<br />
                  • LLC that has filed Form 8832 or
                  2553 electing to be taxed as a
                  corporation

                </td>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  Limited liability company and enter the appropriate tax
                  classification. P= Partnership; C= C corporation; or S=
                  S corporation
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  • Partnership
                </td>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  Partnership
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  • Trust/estate
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top"
                  }}
                >
                  Trust/estate
                </td>
              </tr>
            </tbody>
          </table>

          <p
            style={{
              fontSize: "10pt",
              fontWeight: "bold",
              margin: "4px 0"
            }}
          >
            {" "}
            Line 3b
          </p>
          <p>
            {" "}
            Check this box if you are a partnership (including an LLC classified as a
            partnership for U.S. federal tax purposes), trust, or estate that has any
            foreign partners, owners, or beneficiaries, and you are providing this
            form to a partnership, trust, or estate, in which you have an ownership
            interest. You must check the box on line 3b if you receive a Form W-8
            (or documentary evidence) from any partner, owner, or beneficiary
            establishing foreign status or if you receive a Form W-9 from any
            partner, owner, or beneficiary that has checked the box on line 3b.
          </p>
          <p><b> Note:</b>A partnership that provides a Form W-9 and checks box 3b may
            be required to complete Schedules K-2 and K-3 (Form 1065). For more
            information, see the Partnership Instructions for Schedules K-2 and K-3
            (Form 1065).</p>
          <p>
            {"  "}
            If you are required to complete line 3b but fail to do so, you may not
            receive the information necessary to file a correct information return with
            the IRS or furnish a correct payee statement to your partners or
            beneficiaries. See, for example, sections 6698, 6722, and 6724 for
            penalties that may apply.
          </p>
          <p
            style={{
              fontSize: "10pt",
              fontWeight: "bold",
              margin: "4px 0"
            }}
          >
            Line 4 Exemptions
          </p>
          <p>
            {" "}
            If you are exempt from backup withholding and/or FATCA
            reporting, enter in the appropriate space on line 4 any
            code(s) that may apply to you
          </p>
          <p>
            {" "}
            <b>Exempt payee code.</b>
          </p>
          <ol
            style={{
              listStyle: "disc inside ",
              margin: "0",
              padding: " 0"
            }}
          >
            <li>
              {" "}
              Generally, individuals (including sole proprietors) are not exempt from
              backup withholding.
            </li>
            <li>
              {" "}
              Except as provided below, corporations are exempt from backup
              withholding for certain payments, including interest and dividends.
            </li>
            <li>
              {" "}
              Corporations are not exempt from backup withholding for payments
              made in settlement of payment card or third-party network transactions.
            </li>
            <li>
              {" "}
              Corporations are not exempt from backup withholding with respect to
              attorneys’ fees or gross proceeds paid to attorneys, and corporations
              that provide medical or health care services are not exempt with respect
              to payments reportable on Form 1099-MISC.
            </li>
          </ol>
          <p>
            {"  "}
            The following codes identify payees that are exempt from backup
            withholding. Enter the appropriate code in the space on line 4.
          </p>

          <ol
            style={{
              listStyle: "decimal inside ",
              margin: "0",
              padding: " 0"
            }}
          >
            <li>
              - An organization exempt from tax under section 501(a), any IRA, or
              a custodial account under section 403(b)(7) if the account satisfies the
              requirements of section 401(f)(2).
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default UpdatedForm3;