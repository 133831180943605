import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user';
import moment from "moment";

export const fetchSupportList = ({ TicketNo, Status, Subject, OEID, RaisedBy, CreatedDate, Category, PageNo, PageSize, SortColumn, SortOrder }) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/AdminSupport/AdminSupportTicketList`,
            //url : `https://3m8x9v061i.execute-api.us-east-2.amazonaws.com/dev/AdminSupportTicketList`,
            //url: `https://6o8vjjljnl.execute-api.us-east-1.amazonaws.com/develop/AdminSupportTicketList`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                TicketNo: TicketNo || 0,
                Status: Status || "",
                Subject: Subject || "",
                OEID: OEID || "",
                RaisedBy: RaisedBy || "",
                CreatedDate: CreatedDate ? moment(CreatedDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
                Category: Category || "",
                PageNo: PageNo || 1,
                PageSize: PageSize || 10,
                SortColumn: SortColumn || "",
                SortOrder: SortOrder || ""
            })
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'SUPPORT_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
                totalCount: responseBody.Data.length> 0 ? responseBody.Data[0].TotalCount : 0
            })
            return true
        }
        dispatch({
            type: 'SUPPORT_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'SUPPORT_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchSupportHistoryList = ({ TicketNo, OEID, IPBU, UserId }) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Support/SupportTicketDetails`,
            //url: `https://qqdwgclje0.execute-api.us-east-1.amazonaws.com/develop/SupportTicketDetails`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                ticketNo: TicketNo || 0,
                lOEID: OEID || 0,
                IPBU: IPBU || 0,
                IUserId: UserId || 0
            })
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'SUPPORT_HISTORY_FETCH_SUCCESS',
                payload: responseBody.Data[0],
            })
            return responseBody.Data[0];
        }
        dispatch({
            type: 'SUPPORT_HISTORY_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'SUPPORT_HISTORY_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const saveComment = ({ TicketNo, UserId, comment }) => async (dispatch) => {

    try {
        dispatch({
            type: 'SAVE_COMMENT_START'
        })
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/AdminSupport/UpdateSupportTicketStatus`,
            //url: `https://6o8vjjljnl.execute-api.us-east-1.amazonaws.com/develop/UpdateSupportTicketStatus`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lticketNo: TicketNo || 0,
                adminComments: comment || "",
                lUserId: UserId,
                lStatus: 3,
            })

        })
        const responseBody = await response.data
        if (!responseBody.error) {
            dispatch({
                type: 'SAVE_COMMENT_SUCCESS',
                payload: comment
            })
            return true
        } else {
            dispatch({
                type: 'SAVE_COMMENT_FAILED',
                payload: responseBody.error || "Oops! Something went wrong."
            })
            return false
        }
    } catch (error) {
        dispatch({
            type: 'SAVE_COMMENT_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchCategoryList = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Support/SupportTicketCategoryType`,
            //url: `https://qqdwgclje0.execute-api.us-east-1.amazonaws.com/develop/SupportTicketCategoryType`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'CATEGORY_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return responseBody.Data
        }
        dispatch({
            type: 'CATEGORY_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'CATEGORY_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchStatusList = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Support/SupportTicketStatus`,
            //url: `https://qqdwgclje0.execute-api.us-east-1.amazonaws.com/develop/SupportTicketStatus`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'STATUS_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return responseBody.Data
        }
        dispatch({
            type: 'STATUS_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'STATUS_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}
