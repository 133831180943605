import React, { Component } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import elanLogo from '~/assets/images/Elan-Logo_300x100.jpg';
import './styles.scss';
import config from "~/config";

export class SSOLoginScreen extends Component {
    constructor(props) {
        super(props)
    }

    handleSSOLogin = () => {
        console.log("config.env: ", config.env);
        switch (config.env) {
            case 'develop':
                window.location.href = 'https://it-federation.usbank.com/idp/startSSO.ping?PartnerSpId=ElanCashInAdmin';
                break;
            case 'uat':
                window.location.href = 'https://uat-federation.usbank.com/idp/startSSO.ping?PartnerSpId=ElanCashInAdmin';
                break;
            case 'Prod':
                window.location.href = 'https://federation.usbank.com/idp/startSSO.ping?PartnerSpId=ElanCashInAdmin';
                break;
        }
    }

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        const errorMessage = urlParams.get('error');

        return (
            <div className='root'
            >
                <Card sx={{ maxWidth: 350, height: 500 }} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <img alt="elan" src={elanLogo}
                            style={{
                                height: '100px',
                                width: '300px'
                            }} />
                    </div>
                    <CardContent>
                        <Button fullWidth={false} variant="contained" color="secondary" style={{
                            width: "250px",
                            height: "50px",
                            marginLeft: "35px",
                            marginTop: "50px"
                        }} onClick={() => this.props.history.push('/login')}>
                            Incedo Login
                        </Button>

                        <Button fullWidth={false} variant="contained"
                            color="primary" style={{
                                width: "250px",
                                height: "50px",
                                marginLeft: "35px",
                                marginTop: "35px"
                            }} onClick={() => this.handleSSOLogin()}>
                            Elan Admin Login
                        </Button>
                        <div style={{ marginTop: '10px', color: 'red' }}>{errorMessage === 'true' ? 'An error occurred during the process. Please try again later or contact support if the problem persists' : ''}</div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img alt="CA$H IN" src={require('~/assets/images/CA$HIN-Logo_70x50.jpg')}
                                style={{
                                    height: '50px',
                                    width: '70px'
                                }} />
                        </div>
                        <Typography style={{
                            color: "black",
                            marginTop: "15px",
                            textAlign: "center",
                            fontSize: "10px"
                        }}>
                            © 2024 Elan Financial Services
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        )
    }
}