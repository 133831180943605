import React from "react";
import { connect } from "react-redux";
import { Paper, AppBar, Tabs, Tab, Box, Grid, Button } from "@material-ui/core";
import { TabPanel, a11yProps } from "~/components/TabPanel/index";
import PromotionSummary from "../PromotionSummary";
import Reconciliation from "../Reconciliation";
import Outstanding from "../Outstanding";
import ProfileMerge from '../ProfileMerge';
import Notifier from "~/components/Notifier/index.js";
import "./styles.scss";

class TabView extends React.Component {
    state = {
        value: 0,
        notifierMessage: "",
        notifierOpen: false,
    };

    componentDidMount = async () => {

    }

    handleNotifierClose = () => {
        this.setState({ notifierMessage: "", notifierOpen: false });
    }

    openNotifier = (message) => {
        this.setState({ notifierMessage: message, notifierOpen: true });
    }

    render() {
        const { value, notifierMessage, notifierOpen, userTypes } = this.state;
        const { users, fetchUsersList, claims } = this.props;
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };
        console.log("tab value", this.state.value);
        let canViewPromotionSummary = claims && claims.includes("reports_promotionsummary_view");
        let canViewRecouncillation  = claims && claims.includes("reports_reconciliation_view");
        let canViewOutstanding  = claims && claims.includes("reports_outstanding_view");
        let canViewProfileMerge = claims && claims.includes("reports_mergeduser_view");
        return (
            <div>
                <Grid container justify="center" className="reportsWrapper">
                    <Grid item xs={11}>
                        <Box my={5} justifyContent="center">
                            <AppBar position="static" className="tabHeader">
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="reports"
                                        >
                                            {canViewPromotionSummary && <Tab label="Promotion Summary Report" {...a11yProps(0)} />}
                                            {canViewRecouncillation && <Tab label="Reconciliation Report" {...a11yProps(1)} />}
                                            {canViewOutstanding && <Tab label="Outstanding Report" {...a11yProps(2)} />}
                                            {canViewProfileMerge && <Tab label="Merged User Report" {...a11yProps(3)} />}
                                        </Tabs>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="tabContent" >
                                            <TabPanel value={value} index={0}>
                                                <PromotionSummary openNotifier={(message) => this.openNotifier(message)} closeNotifier={() => this.handleNotifierClose()} claims={claims} />
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                {<Reconciliation openNotifier={(message) => this.openNotifier(message)} closeNotifier={() => this.handleNotifierClose()} claims={claims} />}
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                {<Outstanding openNotifier={(message) => this.openNotifier(message)} closeNotifier={() => this.handleNotifierClose()} claims={claims} />}
                                            </TabPanel>
                                            <TabPanel value={value} index={3}>
                                                {<ProfileMerge openNotifier={(message) => this.openNotifier(message)} closeNotifier={() => this.handleNotifierClose()} claims={claims} />}
                                            </TabPanel>
                                        </div>
                                    </Grid>
                                </Grid>

                            </AppBar>


                        </Box>
                    </Grid>
                </Grid>
                <Notifier message={notifierMessage} open={notifierOpen} handleClose={this.handleNotifierClose} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, {

})(TabView);
