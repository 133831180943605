import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RewardListView from '../Rewards/ListView';
import FileUpload from '../Rewards/FileUpload';
import RewardsUploadStatus from '../Rewards/Status';
import SweepStakeFileUpload from '../Rewards/FileUpload/sweepstake';
import RewardsSweepStakeUploadStatus from '../Rewards/Status/sweepstakestatus';
import QaAuditFileUpload from './FileUpload/qaaudit';
import RewardsQaAutdiUploadStatus from './Status/qaauditstatus';
import './styles.scss'


class AuthRoute extends Component {

    isAllowed(claims, name) {
        let permissions = claims;
        let str = `${name && name.toLowerCase()}`;
        let isEnabled = permissions && permissions.includes(str);
        if (isEnabled) {
            return true
        }
        return false;
    }

    render() {
        const { component: Component, name, claims, ...rest } = this.props;
        let isAccessable = this.isAllowed(claims, name);
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

export class Rewards extends Component {
    render() {
        const { claims } = this.props;
        return (
            <Fragment>
                <Switch>
                    <AuthRoute exact path='/rewards' component={RewardListView} claims={claims} name={"rewards_view"} />
                    <AuthRoute exact path='/rewards/upload' component={FileUpload} claims={claims} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/rewards/upload/status/:FileRecordID' component={RewardsUploadStatus} claims={claims} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/rewards/uploadsweepstake' component={SweepStakeFileUpload} claims={claims} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/rewards/uploadsweepstake/status/:FileRecordID' component={RewardsSweepStakeUploadStatus} claims={claims} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/rewards/uploadqaaudit' component={QaAuditFileUpload} claims={claims} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/rewards/uploadqaaudit/status/:FileRecordID' component={RewardsQaAutdiUploadStatus} claims={claims} name={"rewards_uploadfulfilment"} />
                    {
                        // <Route exact path='/promotion/update/:promotionId' component={PromotionUpdateView} />
                    }
                </Switch>
            </Fragment>
        )
    }
}

export default Rewards;