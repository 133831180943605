import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'
import currency from 'currency.js';
import moment from "moment";

export const fetchTaxationList = ({PortBu, Oeid, OfficerName, Rewards, Status, SubmittedBy, selectedYear, UpdatedDate, PageNo, PageSize, SortColumn, SortOrder }) => async (dispatch) => {

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/W9AdminTaxGird`,
            //url: `https://jff7h8vmt7.execute-api.us-east-2.amazonaws.com/dev/W9AdminTaxGird`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lPBU: PortBu || "",
                lOEID: Oeid || "",
                lOfficerName: OfficerName || "",
                lAmount: Rewards === "" ? "" : currency(Rewards).value,
                lSTATUS: Status || 0,
                lSubmittedBy: SubmittedBy || "",
                lPromotionId: 0,
                lSubmittedDate: UpdatedDate ? moment(UpdatedDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
                lYEAR: selectedYear || "",                
                SortColumn: SortColumn || "",
                SortOrder: SortOrder || "",
                PageNo: PageNo || 1,
                PageSize: PageSize || 10
            })
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'TAXATIONS_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
                totalCount: responseBody.TotalCount
            })
            return true
        }
        dispatch({
            type: 'TAXATIONS_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'TAXATIONS_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchTaxationOverview = ({selectedYear}) => async (dispatch) => {

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/W9AdimDashBoard`,
            //url:`https://jff7h8vmt7.execute-api.us-east-2.amazonaws.com/dev/W9AdimDashBoard`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lYear: selectedYear || "",
            })
        })
        const responseBody = await response.data;

        if (!responseBody.error) {
            dispatch({
                type: 'TAXATIONS_OVERVIEW_FETCH_SUCCESS',
                payload: responseBody.Data
            })
            return true
        }
        dispatch({
            type: 'TAXATIONS_OVERVIEW_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'TAXATIONS_OVERVIEW_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchStatusList = () => async (dispatch) => {
    dispatch({
                type: 'TAXATION_STATUS_LIST_FETCH_SUCCESS',
                payload: [{"label":"Submitted-Tr", value:3},{"label":"Submitted-TnR", value:4},
            {"label":"Not Submitted-Tr", value:1},{"label":"Not Submitted-TnR", value:2}],
            })
            return [{"label":"Submitted-Tr", value:3},{"label":"Submitted-TnR", value:4},
            {"label":"Not Submitted-Tr", value:1},{"label":"Not Submitted-TnR", value:2}];
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/TaxationStatus`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/RewardStatus`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'TAXATION_STATUS_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return responseBody.Data
        }
        dispatch({
            type: 'TAXATION_STATUS_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'TAXATION_STATUS_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchYearList = () => async (dispatch) => {

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/W9GetYear`,
            //url: `https://7j6vnigzd7.execute-api.us-east-2.amazonaws.com/dev/W9GetYear`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'TAXATION_YEAR_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
                currentYear: responseBody.Data? responseBody.Data[0].value:""
            })
            return responseBody
        }
        dispatch({
            type: 'TAXATION_YEAR_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'TAXATION_YEAR_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const removeReward = ({rewardId, username}) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/DeleteRewardsDetails`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/DeleteRewardsDetails`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lRewardID: rewardId || 0,
                lUserName: username || '',
            })
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'REMOVE_REWARD_SUCCESS',
                payload: {rewardId:rewardId}
            })
            return true;
        }
        dispatch({
            type: 'REMOVE_REWARD_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false;
    } catch (error) {
        dispatch({
            type: 'REMOVE_REWARD_FAILED',
            payload: error.message || "An error has occured."
        })
        return false;
    }
}

export const fetchTaxDetails = ({year, ssn}) => async (dispatch) => {

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/W9GetFormForAdmin`,
            //url:`https://jff7h8vmt7.execute-api.us-east-2.amazonaws.com/dev/W9GetFormForAdmin`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lSSN: ssn || "",
                lYear: year || "",
            })
        })
        const responseBody = await response.data;

        if (!responseBody.error) {
            dispatch({
                type: 'W9FORM_FETCH_SUCCESS',
                payload: responseBody.Data? responseBody.Data[0]:null
            })
            return true
        }
        dispatch({
            type: 'W9FORM_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'W9FORM_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchTaxFillOutForm = ({year, pbu, oeid}) => async (dispatch) => {

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Taxation/W9AdminFillOutFormData`,
            //url:`https://jff7h8vmt7.execute-api.us-east-2.amazonaws.com/dev/W9AdminFillOutFormData`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lPBU: pbu || "",
                lOEID: oeid || "",
                lYear: year || "",
            })
        })
        const responseBody = await response.data;

        if (!responseBody.error) {
            dispatch({
                type: 'W9FORM_FETCH_SUCCESS',
                payload: responseBody.Data? responseBody.Data[0]:null
            })
            return true
        }
        dispatch({
            type: 'W9FORM_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'W9FORM_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const saveW9Form = ({formData, userId, editOEID, editPBU, status, canEditSSN}) => async (dispatch) => {

    const url= !canEditSSN ? `${config.base_url}/api/Taxation/W9AdminUpdateTaxFormData`: `${config.base_url}/api/Taxation/W9InsertUpdateTaxFormData`;
    //const url= !canEditSSN ? `https://jff7h8vmt7.execute-api.us-east-2.amazonaws.com/dev/W9AdminUpdateTaxFormData`: `https://7j6vnigzd7.execute-api.us-east-2.amazonaws.com/dev/W9InsertUpdateTaxFormData`;

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url:url,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                "lName": formData.Name ||"",
                "lBusinessName":formData.BusinessName ||"",
                "lFederalTaxClassificationId":formData.FederalTaxClassificationId ||"",
                "lIsForeignPartners":formData.IsForeignPartners||false,
                "lExemptPayeeCode":formData.ExemptPayeeCode ||"",
                "lFATCACode":formData.FATCACode ||"",
                "lAddress1":formData.Address1 ||"",
                "lAddress2":formData.Address2 ||"",
                "lCity":formData.City ||"",
                "lState": formData.State ||"",
                "lZipCode":formData.ZipCode ||"",
                "lListAccountNumber":formData.ListAccountNumber ||"",
                "lSSN":formData.SSN ||"",
                "lOEID":formData.OEID || editOEID ||"",
                "lPBU":formData.PBU || editPBU ||"",
                "lSignatureText":formData.SignatureText ||"",
                "lSignatureDate": moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                "lEmployerIdentificationNumber":formData.EmployerIdentificationNumber ||"",
                "lLimitedLiability":formData.LimitedLiability ||"",
                "lOthersText":formData.OthersText ||"",
                "lRequestersNameandAddress":formData.RequestersNameandAddress ||"",
                "lCreatedBy":userId || "",
                "lCreatedOn":"",
                "lStatus":status,
                "YEAR":moment().year()
            })
        })
        const responseBody = await response.data;

        if (!responseBody.error) {
            const responseStatus = responseBody.Data && responseBody.Data[0] && responseBody.Data[0].Status || "";
            if(responseStatus == "Fail"){
                dispatch({
                    type: 'W9FORM_SAVE_FAILED',
                    payload: responseBody.Data[0].Message || "Oops! Something went wrong."
                })
                return false
            }
            dispatch({
                type: 'W9FORM_SAVE_SUCCESS',
                payload: formData
            })
            return true
        }
        dispatch({
            type: 'W9FORM_SAVE_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'W9FORM_SAVE_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}