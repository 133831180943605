import React, { Fragment } from "react";
import { connect } from 'react-redux'
import {
  Link, CircularProgress, OutlinedInput, TextField, InputAdornment, Grid, Box, Paper, Container, Button
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ConfirmDialog, AlertDialog } from '~/components/Dialogs';
import { fetchRenewalDate, saveRenewalDate, fetchTaxGroupDate, saveTaxGroupDate } from '~/redux/actions/settings';
import Notification from '~/components/Notification';
import moment from 'moment';

class Configuration extends React.Component {
    state = {
        isLoading: true,
        updateRenewalDateProgress:false,
        editRenewalDate:false,
        editTaxGroupDate:false,
        updatetaxGroupDateProgress:false,
        renewalDate: '',
        taxGroupDate: '',
        clientId: 1,
        alertMessage: null
    };

    componentDidMount() {
        const {info} = this.props.user;
        this.props.dispatch(fetchRenewalDate({userId:info.Id})).then(() => {
            this.setState({
                isLoading: false,
                renewalDate: this.props.configuration.renewalDate,
                editRenewalDate:this.props.configuration.renewalDate? false:true
            })
        })
        this.props.dispatch(fetchTaxGroupDate({userId:info.Id})).then(() => {
            this.setState({
                isLoading: false,
                taxGroupDate: this.props.configuration.taxGroupDate,
                editTaxGroupDate:this.props.configuration.taxGroupDate? false:true
            })
        })
    }

    isDateValid(newDate) {
        const newDateTest  = newDate? moment.utc(newDate).local().format('YYYY-MM-DD'): "";

        if (!newDate){
            this.setState({alertMessage: 'Date field is required.'});
            return false;
        }
    }

    handleSave =() => {
        const { renewalDate } = this.state;

        if (!renewalDate){
            this.setState({alertMessage: 'W9 renewal Date is required.'});
            return false;
        }       

        this.setState({
            updateRenewalDateProgress: true
        }, () => {
            //handle validation
            const {info} = this.props.user;
            this.props.dispatch(saveRenewalDate({renewalDate, userId:info.Id})).then((response)=>{
                if(!response){
                    this.setState({
                        updateRenewalDateProgress: false
                    })
                    return;
                }
                this.setState({
                    alertMessage: 'W9 renewal Date successfully configured.',
                    updateRenewalDateProgress: false,
                    editRenewalDate:false
                })
            });
        });
        //handle Error
        //this.setState({error:"Error here"});
    }

    handleTaxGroupDateSave =() => {
        const { taxGroupDate } = this.state;

        if (!taxGroupDate){
            this.setState({alertMessage: 'W9 Submission Date is required.'});
            return false;
        } 

        this.setState({
            updatetaxGroupDateProgress: true
        }, () => {
            //handle validation
            const {info} = this.props.user;
            this.props.dispatch(saveTaxGroupDate({taxGroupDate, userId:info.Id})).then((response)=>{
                if(!response){
                    this.setState({
                        updatetaxGroupDateProgress: false
                    })
                    return;
                }
                this.setState({
                    alertMessage: 'W9 Submission Date successfully configured.',
                    updatetaxGroupDateProgress: false,
                    editTaxGroupDate:false
                })
            });
        });
        //handle Error
        //this.setState({error:"Error here"});
    }

    hideAlertMessage = () => {
        console.log("hideAlertMessage");
        this.setState({
            alertMessage: null,
        })
    }

    handleDateChangeRawRenewal = (e, renewalDate) => {
        e.preventDefault();
        return false;
    }

    handleDateChangeRenewal = (date, renewalDate) => {
        this.setState({
            renewalDate: moment(moment(date)).utc().format("MM/DD/YYYY HH:mm:ss"),
        });
    }

    handleDateChangeTaxationGroupDate = (date, taxGroupDate) => {
        this.setState({
            taxGroupDate: moment(moment(date)).utc().format("MM/DD/YYYY HH:mm:ss"),
        });
    }
    handleTaxGroupDateEdit =()=>{
        this.setState({editTaxGroupDate:true});
    }

    handleRenewalDateEdit =()=>{
        this.setState({editRenewalDate:true});
    }

    render() {
        const {editRenewalDate, editTaxGroupDate, isLoading, renewalDate, taxGroupDate, updateRenewalDateProgress, updatetaxGroupDateProgress, alertMessage} = this.state;
        const {configuration, permissions} = this.props;
        const claims = permissions.minified;
        const canSubmit = claims && claims.includes("configuration_submit");

        if (isLoading) {
            return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
        }

        return (
            <Fragment>
                <Grid container>
                    <Grid item md={3}>
                        <label className="inputLabel">W9 Renewal Date</label>
                        <DatePicker
                            onChangeRaw={(e, renewalDate) => this.handleDateChangeRawRenewal(e, renewalDate)}
                            onSelect={(date) => this.handleDateChangeRenewal(date, renewalDate)}
                            disabled={!editRenewalDate}
                            minDate={new Date()}
                            selected={renewalDate && new Date(moment.utc(renewalDate).local().format("MM/DD/YYYY HH:mm:ss")).getTime()}
                            customInput={<OutlinedInput
                                variant="outlined"
                                className="full-width"
                                endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                            />}
                            >
                        </DatePicker>
                    </Grid>
                    {canSubmit && <Grid item md={3}>
                        {updateRenewalDateProgress ? (
                            <Box width="100px" mt={3} display="flex" justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                            ) :( <Box display="flex" mt={3} justifyContent="flex-start" alignItems="flex-end" className="full-width">
                                    {editRenewalDate?
                                        <Button variant="contained" color="primary" onClick={()=>this.handleSave()} >
                                            Save
                                        </Button>:<Link href="javascript:void(0);" className="editLinkBtn" onClick={()=>this.handleRenewalDateEdit()}>EDIT</Link>
                                    }
                               </Box>)
                        }
                    </Grid>}
                </Grid>
                <Grid container >
                    <Grid item md={3}>
                        <label className="inputLabel">W9 Submission to Taxation Group</label>
                        <DatePicker
                            onChangeRaw={(e, taxGroupDate) => this.handleDateChangeRawRenewal(e, taxGroupDate)}
                            onSelect={(date) => this.handleDateChangeTaxationGroupDate(date, taxGroupDate)}
                            disabled={!editTaxGroupDate}
                            minDate={new Date()}
                            selected={taxGroupDate && new Date(moment.utc(taxGroupDate).local().format("MM/DD/YYYY HH:mm:ss")).getTime()}
                            customInput={<OutlinedInput
                                variant="outlined"
                                className="full-width"
                                endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                            />}
                            >
                        </DatePicker>
                    </Grid>
 
                    {canSubmit &&<Grid item md={3}>
                        {updatetaxGroupDateProgress ? (
                            <Box width="100px" display="flex" mt={3} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                            ) : 
                            
                                (<Box display="flex" mt={3} justifyContent="flex-start" alignItems="flex-end" className="full-width">
                                    {editTaxGroupDate?
                                        <Button variant="contained" color="primary" onClick={()=>this.handleTaxGroupDateSave()} >
                                            Save
                                        </Button>:<Link href="javascript:void(0);" className="editLinkBtn" onClick={()=>this.handleTaxGroupDateEdit()}>EDIT</Link>
                                        
                                    }
                                </Box>)
                            }
                    </Grid>}
                </Grid>
                {alertMessage && this.renderAlertMessage('', alertMessage, '')}
                {configuration.error && this.renderSnackbar(configuration.error)}
            </Fragment>
        );
    }

    renderAlertMessage = (title, message, callbackType) => {
        return <AlertDialog title={title} message={message} onConfirm={()=>this.hideAlertMessage()} />
    }

    renderSnackbar = message => {
        return <Notification variant="error" message={message} />
    }
}

export default connect(state => (
    { ...state.user, ...state.configuration, ...state.permissions }
))(Configuration)