import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'

let userTypes = null;
export const getAccessToken = async () => {
	const cookies = new Cookies(window.document.cookie)
	const refreshToken = cookies.get('refreshToken')
	const accessToken = cookies.get('accessToken')
	if(accessToken){
		return accessToken
	}
	if(refreshToken){
		try{
			const response = await axios({
				url: `${config.base_url}/api/OAuth/oauth/token?refreshToken=${refreshToken}`,
                //url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/token?refreshToken=${refreshToken}`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				}
			})
			const responseBody = await response.data
			if(responseBody.success) {
				cookies.set('accessToken', responseBody.accessToken, { path: '/' })
				return responseBody.accessToken
			}
			return null
		}catch(error){
			return null
		}
	}
	return null
}

export const updateUserActionItems = async (data) => {
	const accessToken = await getAccessToken();
	try {
		const response = await axios({
			url: `${config.base_url}/api/Settings/UpdateUserActionItem`,
			//url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/UpdateUserActionItem`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify(data)
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}


export const getUserTypes = async (data) => {
	const accessToken = await getAccessToken();
	try {
		if (!userTypes || userTypes.result === null) {
			const response = await axios({
				url: `${config.base_url}/api/Settings/GetUserTypes`,
				//url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/GetUserTypes`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				},
				data: JSON.stringify(data)
			})
			const responseBody = await response.data;
			userTypes = responseBody;
			return responseBody;
		} else {
			return userTypes;
		}
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const checkSessionTimout = async () => {
	const cookies = new Cookies(window.document.cookie);
	const accessToken = cookies.get('accessToken');

    if(accessToken){
        try{
            const response = await axios({
				url: `${config.base_url}/api/AdminAuthorizer/oauth/checkAdminSession`,
				//url: `https://s3yqz3bxsc.execute-api.us-east-2.amazonaws.com/dev/outh/checkAdminSession`,
                method: 'GET',
				headers: {
					'Content-Type': 'application/json',
                    'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data
			if(responseBody.success) {
                return true
			}
			return false
		}catch(error){
			return false
		}
	}
    return false
}

//Update token/session time
export const keepSessionLive = async () => {
    const cookies = new Cookies(window.document.cookie);
    const refreshToken = cookies.get('refreshToken');
    const accessToken = cookies.get('accessToken');

	if(refreshToken){
		try{
			const response = await axios({
				url: `${config.base_url}/api/OAuth/oauth/token?refreshToken=${refreshToken}`,
				//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/token?refreshToken=${refreshToken}`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
                    'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data;
			if (!responseBody.error) {
                const { accessToken, refreshToken } = responseBody;
                cookies.set('accessToken', accessToken, { path: '/' });
                cookies.set('refreshToken', refreshToken, { path: '/' });
				return true;
			}
			return false;
		}catch(error){
			return false;
		}
	}
	return false;
}