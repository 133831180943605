import React from 'react';
import { Button, Grid, Box, DialogTitle, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import './styles.scss'



export function ConfirmDialog(props) {
    const { title, message, onConfirm, onCancel , open=true } = props
    return (
        <div id="mainDialogs">
            <Dialog
                open={open}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <Box py={6} px={6}>
                    <DialogTitle className="dialogTitle">{title}</DialogTitle>
                    <DialogContent>
                        <div className="dialogContent">{message}</div>
                    </DialogContent>
                </Box>
                <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                    <Button variant="contained" className="noBtn" fullWidth={true} onClick={onCancel} color="primary">
                        No
                    </Button>
                    <Button variant="contained" className="yesBtn" fullWidth={true} onClick={onConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
}


export function AlertDialog(props) {
    const { dialogClassName="", title, message, onConfirm, open=true } = props
    return (
        <Dialog
            open={open}
            onClose={onConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={dialogClassName || ""}
        >
            <Box py={6} px={6}>
                {title && <DialogTitle className="alert-dialog-title dialogTitle">{title}</DialogTitle>}
                {message && <DialogContent className="alert-dialog-message"><Box color="primary.main" mb={2}><div className="dialogContent">{message}</div></Box></DialogContent>}
                <DialogActions>
                    <Grid container justify="center">
                        <Grid item xs={2}>
                            <Button variant="contained" className='okBtn' fullWidth="true" onClick={onConfirm} color="primary" autoFocus>
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Box>
        </Dialog>
    );
}