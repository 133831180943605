import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom';
import TabView from './TabView';
import UserPermissions from './UserPermissions';
import User from './User';
import Notifier from "~/components/Notifier/index.js";
import "./styles.scss";


class AuthRoute extends Component {
    render() {
        const { component: Component, isAccessable, claims,...rest } = this.props;
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} claims={claims} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

class Settings extends Component {
    render() {
        const { info, claims } = this.props;
        let isAccessable = info && info.UserTypeID === 1;
        return (
            <div className="settingsWrapper">
                <Fragment>
                    <Switch>
                        <AuthRoute exact path='/settings' component={TabView} isAccessable={isAccessable} claims={claims} />
                        <AuthRoute exact path='/settings/permissions/:userId' component={UserPermissions} isAccessable={isAccessable}/>
                        <AuthRoute exact path='/settings/addUser' component={User} isAccessable={isAccessable}/>
                        <AuthRoute exact path='/settings/editUser/:userId/:source' component={User} isAccessable={isAccessable}/>
                    </Switch>
                </Fragment>
            </div>
        )
    }
}


export default Settings