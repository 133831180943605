import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import { MenuItem, TextField, Icon, InputAdornment, OutlinedInput, Grid, Paper, Box, Button, Collapse, CircularProgress, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell, TableSortLabel } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import moment from 'moment';
import DatePicker from "react-datepicker";
import DateRangeIcon from '@material-ui/icons/DateRange'
import { fetchStatusList, fetchTaxationList, fetchTaxationOverview, fetchYearList, fetchTaxDetails, fetchTaxFillOutForm, saveW9Form } from '~/redux/actions/taxation'
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import TaxationOverview from '../TaxationOverview/';
import Notification from '~/components/Notification';
import { AlertDialog } from '~/components/Dialogs'
import './styles.scss'
import currency from 'currency.js';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import CurrencyInput from '~/components/CurrencyInput';
import TaxForm from "../TaxForm/";

class TaxationListView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			fetchingList: true,
			fetchingDetail: false,
			expandedItem: null,
			showFilter: false,
			page: 0,
			rowsPerPage: 10,
			filterOpen: false,
            PortBu:'',
            Oeid:'',
            OfficerName:'',
            Rewards:null,
            Status:null,
            SubmittedBy:null,
            UpdatedDate:null,
			statusList: null,
			yearList: null,
            selectedYear:"",
            taxationData:null,
            showForm:false,
            formData:null,
            processingView:false,
            processingEdit:false,
            processingDraft:false,
            processingSubmit:false,
            formPageNo:1,
			SortColumn: "",
            alertMessage: null,
            alertMessageCallbackType: null,
			SortOrder: "",
            errors:null,
            valid:false,
            editOEID:null,
            editPBU:null,
            canEditSSN:false
		};

	}

    componentDidMount= async()=> {
		const { accessToken, portbu, oeid } = this.props.user.info;

        await this.getYearList();

        this.getTaxationOverview();
		this.getTaxationList();
		this.getStatusList();
    }

	toggleListItem = (item, index) => {

	}

	handleSorting(SortColumn) {
		const { SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
		this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, fetchingList: true }, () => {
			this.getTaxationList(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
		});
	}

	filterCliCkFun = () => {
		this.setState({
			filterOpen: !this.state.filterOpen
		})
	}

	clearFilter = () => {
		this.setState({
            PortBu:'',
            Oeid:'',
            OfficerName:'',
            Rewards:null,
            Status:null,
            SubmittedBy:null,
            UpdatedDate:null,
    }, () => {this.getTaxationList()});
	}

	handlePageChange = (event, page) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page
		}, () => this.getTaxationList(SortColumn, newSortOrder))
	}

	handleRowsPerPageChange = (event) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page: 0,
			rowsPerPage: parseInt(event.target.value, 10)
		}, () => this.getTaxationList(SortColumn, newSortOrder))
	}

    getTaxationOverview=()=>{
        const {selectedYear} = this.state;

        this.props.dispatch(fetchTaxationOverview({selectedYear})).then((response) => {
            if(!response) {
                this.setState({ 
                    alertMessage: this.props.taxations.error,
                    alertMessageCallbackType: null,
                });
                return false;
            }
			this.setState({ taxationData: this.props.taxations.taxationOverview });
		});
    }

    getTaxationList = (SortColumn, SortOrder) => {
		const {PortBu, Oeid, OfficerName, Rewards, Status, SubmittedBy, selectedYear, UpdatedDate, page, rowsPerPage } = this.state
        //Validate both year filters
        const flag = this.validateYearFilter();
        if (!flag) {
            return false;
        }

		this.setState({
			fetchingList: true
		}, () => {
			this.props.dispatch(fetchTaxationList({ PortBu, Oeid, OfficerName, Rewards, Status, SubmittedBy, selectedYear, UpdatedDate, PageNo: page + 1, PageSize: rowsPerPage, SortColumn: SortColumn || "", SortOrder: SortOrder || "" })).then((response) => {
				if(!response) {
                    this.setState({ 
                        alertMessage: this.props.taxations.error,
                        alertMessageCallbackType: null,
                    });
                    return false;
                }

                this.setState({
					isLoading: false,
					fetchingList: false
				})
			})
		})
	}

	handleYear = (event) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page: 0,
			rowsPerPage: 10,
            selectedYear: event.target.value
		}, () => {
                this.getTaxationOverview();
                this.getTaxationList(SortColumn, newSortOrder)
            }
        )
	}

    validateYearFilter =()=>{
        const {selectedYear, UpdatedDate} = this.state;
        if(UpdatedDate){
            const filterYear = moment(UpdatedDate, "MM/DD/YYYY").year();
            if(filterYear!=selectedYear){
                this.setState({
                    alertMessage: 'Please, enter the date range within the selected year.',
                })
                return false;
            }
        }
        return true;        
    }

    hideAlertMessage = () => {
        this.setState({
            alertMessage: null,
            alertMessageCallbackType: null,
        })
    }

    goBack = () => {
        this.setState({
            alertMessage: null,
            alertMessageCallbackType: null,
            showForm: false,
        })
    }

    getStatusList = () => {
		this.props.dispatch(fetchStatusList()).then((response) => {
			//set state here on success
			if (!response)
                return false;
			this.setState({
                statusList: response
            })
        })
    }

    getYearList = async () => {
		await this.props.dispatch(fetchYearList()).then((response) => {
			//set state here on success
			if (!response)
				return false;
			this.setState({
				yearList: this.props.taxations.yearList,
                selectedYear:this.props.taxations.selectedYear
			})
		})
	}

   findColor = (status) => {
       let textColor="#ebba8f";
       switch (status) {
			case 'Submitted-TR':
			case 'Submitted-TnR':
				textColor= "#286787"
                break;
			case 'Not Submitted-TR':
			case 'Not Submitted-TnR':
				textColor= "#ebba8f"
                break;
			default:
				textColor= "#ebba8f"
                break;
		}
        return textColor;
	}

    handleCancel =()=>{

        this.setState({
            formData:this.props.taxations.formData,
            processingEdit:false
        });
    }

    handleAccountEdit =(item, canSubmit, event) =>{
        console.log(item.OEID, item.PBU, item.SSN);
        //fetch User w9 form data
        this.setState({
            processingView:true,
            processingEdit:false,
            errors:null,
        }, ()=>{
               if(item.SSN){
                  this.props.dispatch(fetchTaxDetails({year: item.YEAR, ssn: item.SSN})).then((response) => {
                    //set state here on success
                    if(!response) {
                        this.setState({
                            processingView:false,
                            alertMessage: this.props.taxations.error,
                            alertMessageCallbackType: null,
                        });
                        return false;
                    }

                    this.setState({
                        formData: this.props.taxations.formData,
                        showForm:true,
                        processingView:false,
                        editOEID:item.OEID,
                        editPBU:item.PBU,
                        canEditSSN:false
                    })
                })
            } else {console.log("canSubmita", canSubmit);
                if(canSubmit){console.log("canSubmitb", canSubmit);
                    //fillout form in case ssn not present
                    this.props.dispatch(fetchTaxFillOutForm({year:item.YEAR, pbu: item.PBU, oeid:item.OEID})).then((response) => {
                        //set state here on success
                        if(!response){
                            this.setState({
                                processingView:false,
                                alertMessage: this.props.taxations.error,
                                alertMessageCallbackType: null,
                            });
                            return false;
                        }

                        this.setState({
                            formData: this.props.taxations.formData || null,
                            showForm:true,
                            processingView:false,
                            editOEID:item.OEID,
                            editPBU:item.PBU,
                            canEditSSN:true,
                            processingEdit:true
                        })
                    })
                } else {
                    this.setState({processingView:false, alertMessage:"You don't have permission to fill W9 form.", alertMessageCallbackType: null });
                }
            }
        });
        
    }

    handleClose =() => {
        this.setState({showForm:false});
    }

    handleFormPageChange =(pageNo) => {
        this.setState({formPageNo: pageNo});
    }

    handleEdit =() => {
        //handle edit
        this.setState({processingEdit:true});
    }

    handleDraft =() => {
        //save Draft w9 form
        this.setState({processingEdit:false});
    }

    handleSubmit =() => {
        //Apply validation
        if(!this.state.processingEdit){
            return false;
        }
        const validForm = this.validateForm();
        
        if(validForm){
            this.setState({
                processingSubmit:true,
            }, async () =>{
                //Submit form
                const { info }  = this.props.user;
                const {formData, editOEID, editPBU, canEditSSN} = this.state;
                //saveW9Form
                await this.props.dispatch(saveW9Form({ formData, userId: info.Id, editOEID, editPBU, status:"Submitted", canEditSSN})).then(response => {
                  console.log("response", response);
                  if (!response) {
                    this.setState({
                        processingSubmit: false,
                        processingEdit:true,
                        alertMessage: this.props.taxations.error,
                        alertMessageCallbackType: null,
                    });
                    return false;
                  }
                  this.setState({
                      processingSubmit: false,
                      editOEID:null,
                      editPBU:null,
                      processingEdit:false,
                      alertMessage: 'W9 form successfully submitted.',
                      alertMessageCallbackType: 'REDIRECT',
                  });
                })

                //refresh listing and tax overview
                const {selectedYear} = this.state;
                this.props.dispatch(fetchTaxationOverview({selectedYear})).then((response) => {
                    this.setState({ taxationData: this.props.taxations.taxationOverview });
                });

                this.getTaxationList(); 
            }
            );

                       
        }
    }

    validateForm=() => {
        const {formData} = this.state;
        let errors = {};
        let valid = true;
        let missingFlag= false;
        let otherValidations={};

        if(!formData || !formData.Name || formData.Name.trim()=== ''){
            errors.name='Name is required.';
            missingFlag=true;
            valid = false;
        }
        //if(!formData.BusinessName || formData.BusinessName.trim()=== ''){
              //errors.businessName='Business Name is required.';
              //valid = true;
        //}
        if(!formData || !formData.FederalTaxClassificationId ){
            errors.federalTaxClassificationId = 'Please, select at least one Federal tax classification.';
            missingFlag=true;
            valid = false;
        } else {
            //Check corresponding text box value
            if(formData.FederalTaxClassificationId == "L" && (!formData.LimitedLiability || formData.LimitedLiability.trim() ==='' )){
                errors.limitedLiability="Please fill limited liability field.";
                missingFlag=true;
                valid = false;
            }
            if(formData.FederalTaxClassificationId == "O" && (!formData.OthersText || formData.OthersText.trim() ==='' )){
                errors.othersText="Please fill limited liability field.";
                missingFlag=true;
                valid = false;
            }
        }
        if(!formData || !formData.Address1 || formData.Address1.trim()=== ''){
            errors.address1 = 'Address is required.';
            missingFlag=true;
            valid = false;
        }
        if(!formData || (!formData.City || formData.City.trim()=== '') &&(!formData.State || formData.State.trim()=== '') && (!formData.ZipCode || formData.ZipCode.trim()=== '')){
            errors.csz ='City, State and ZIP code is required.';
            missingFlag=true;
            valid = false;
        } else {
            const vldMsg = "The input must be in valid format:\n CITY,ST,ZIP CODE \n Example: Milwaukee,WI,53202";
            let cityMsg ="";
            if(!formData.City || formData.City.trim()=== '' || !formData.State || formData.State.trim()=== '' || !formData.ZipCode || formData.ZipCode.trim()=== ''){
                otherValidations.csz = "The input must be in valid format:\n CITY,ST,ZIP CODE \n Example: Milwaukee,WI,53202";
                valid = false;
            }

            if(!formData.City || formData.City.trim()=== ''){
                errors.csz='City is required.';
                //otherValidations.city = 'The input must be in valid format: CITY,ST,ZIP CODE Example: Milwaukee,WI,53202';
                //missingFlag=true;
                valid = false;
            } else {
                if(formData.City.trim().length>30) {
                    errors.csz = 'Maximum character length allowed for City is 30.'; 
                    ///otherValidations.city ='Maximum character length allowed for City is 30.';
                    cityMsg ='Maximum character length allowed for City is 30.';
                    valid = false;
                }
            }
            if(!formData.State || formData.State.trim()=== ''){
                errors.csz='State is required.';
                //otherValidations.city = 'The input must be in valid format: CITY,ST,ZIP CODE Example: Milwaukee,WI,53202';
                //missingFlag=true;
                valid = false;
            }else {
                if(formData.State.trim().length!=2) {
                    errors.csz ='State Code must be of 2 Letters.';
                    ///otherValidations.state = 'state code must 2 Letters.';
                    cityMsg = cityMsg ?(cityMsg + '\rState Code must be of 2 Letters.') : 'State Code must be of 2 Letters.';
                    valid = false;
                }
            }
            if(!formData.ZipCode || formData.ZipCode.trim()=== ''){
                errors.csz='ZIP code is required.';
                //otherValidations.city = 'The input must be in valid format: CITY,ST,ZIP CODE Example: Milwaukee,WI,53202';
                //missingFlag=true;
                valid = false;
            } else {
                if (formData.ZipCode.trim().length > 16) {
                    errors.csz = 'Zip Code must be of 16 digits.';
                    ///otherValidations.zipCode = 'Zip Code must be of 16 digits.';
                    cityMsg = cityMsg ?(cityMsg + '\rZIP Code must be less than or \requal to 16 digits.') : 'ZIP Code must be less than or \requal to 16 digits.';
                    valid = false;
                }
            }

            if(cityMsg !== ""){
                 otherValidations.csz = vldMsg + "\n\n Note: " + cityMsg ;
            }

        }

        //removed validation 27/4/2020
        //if(formData && formData.ListAccountNumber && formData.ListAccountNumber.trim()!== ''){
          //  const ac_nos = formData.ListAccountNumber.split(",");
            //for(let ac_no of ac_nos){
              //  if(isNaN(ac_no) || ac_no.trim().trim().length !=16){
                //    errors.listAccountNumber = 'Account Number must be of 16 digits.';
                  //  otherValidations.listAccountNumber = 'Account Number must be of 16 digits.';
                   // valid = false;
                //}
            //}
        //}
        if(!formData ||!formData.SSN || formData.SSN.trim()=== ''){
            errors.ssn ="Missing Mandatory field SSN.";
            missingFlag=true;
            valid = false;
        } else if(isNaN(formData.SSN) || formData.SSN.length !=9){
            errors.ssn ="SSN must be of 9 digits.";
            otherValidations.ssn = "SSN must be of 9 digits.";
            valid = false;
        }

        if(formData && formData.EmployerIdentificationNumber && formData.EmployerIdentificationNumber.trim()!== ''){
            if(isNaN(formData.EmployerIdentificationNumber) || formData.EmployerIdentificationNumber.length !=9){
                errors.ein = 'Employer identification number must be of 9 digits.';
                otherValidations.ein = "Employer identification number must be of 9 digits.";
                valid = false;
            }
        }
        if(!formData || !formData.SignatureText || formData.SignatureText.trim()=== ''){
            errors.signatureText ='Signature is required.';
            missingFlag=true;
            valid = false;
        }else if(formData.SignatureText.trim().toLowerCase() != formData.Name.trim().toLowerCase()){
            errors.signatureText ='Name and Signature must be same.';
            otherValidations.signatureText = "Name and Signature must be same.";
            valid = false;
        }

        //if(!formData || !formData.SignatureDate || formData.SignatureDate.trim()=== ''){
            //errors.signatureDate='Signature Date is required.';
            //valid = false;
        //}

        this.setState({errors:errors, valid:valid}, ()=> {
            if (!valid) {
                const items = otherValidations && Object.values(otherValidations);
                if(missingFlag){
                    items.unshift("Missing Mandatory Fields.");
                }
                const msg = items.length && <Box py={1} px={1} color="error.main">
                    <ul className="error">
                        {items.map((err) => {
                            let newText = err.split('\n').map((item, i) => {
                                if(i==0){
                                    return item;
                                }
                                
                                let subText = item.split('\r').map((subitem, subind) => {
                                    if(subind==0){
                                        return <p key={subind}>{subitem}&nbsp;</p>;
                                    }
                                    return <p key={subind} style={{paddingLeft:"40px"}}>{subitem}&nbsp;</p>;
                                });
                                return item? subText: <span style={{display:"block", height:"5px"}}>&nbsp;</span>;
                            });
                            return <li key={err}>{newText}</li>
                            }
                        )}
                    </ul>
                </Box>
                this.setState({ alertMessage: msg, alertMessageCallbackType: null });
            } else {
                this.setState({ alertMessage: null, alertMessageCallbackType: null });
            }
        });

        return valid;
    }

    handleChange =(field, event, position)=>{
        const {formData} = this.state;
        const newData = {...formData};
        switch(field){
            case 'csz':
                const csz         =  event.target.value;
                newData[field]    =  event.target.value;
                const cszValues   =  csz.split(",");
                console.log(cszValues);
                newData["City"]   =  cszValues[0]?cszValues[0].trim():"";
                newData["State"]  =  cszValues[1]?cszValues[1].trim():"";
                newData["ZipCode"]=  cszValues[2]?cszValues[2].trim():"";
                
            break;
            case 'SSN':
                const ssnString = formData && formData.SSN || "";
                const strArray  = ssnString.split("");
                strArray[position] = event.target.value;
                newData[field]     = strArray.join("");
            break;
            case 'EmployerIdentificationNumber':
                const einString = formData && formData.EmployerIdentificationNumber || "";
                const einArray  = einString.split("");
                einArray[position] = event.target.value;
                newData[field]     = einArray.join("");
               // newData[field] =  formData && formData.EmployerIdentificationNumber.concat("", event.target.value)|| event.target.value;
            break;
            case 'LimitedLiability':
            case 'ExemptPayeeCode':
            case 'FATCACode':
                newData[field]= event.target.value.toUpperCase();
            break;
            case 'SignatureDate':
                newData[field]= moment(moment(event)).utc().format("MM/DD/YYYY HH:mm:ss")
            break;
            case 'IsForeignPartners':
                 newData[field] = event.target.checked;
	             break;
            default:
                newData[field]=  event.target.value;
            break
        }
        
        this.setState({formData:{...newData}});
        console.log("new form data", newData);
    }

    render() {
		const {formPageNo, processingView, processingEdit, processingDraft, processingSubmit, 
        formData, showForm, canEditSSN,
            alertMessage, alertMessageCallbackType, taxationData, statusList, yearList, selectedYear, isLoading, 
        filterOpen, fetchingList, page, rowsPerPage, expandedItem, 
        fetchingDetail, showFilter, PortBu, Oeid, OfficerName, Rewards, Status, 
        SubmittedBy, UpdatedDate, SortColumn, SortOrder, errors} = this.state;

		const { user, taxations, permissions } = this.props;
		const claims = permissions.minified;
        const canSubmit = claims && claims.includes("w9form_submit");

        if (isLoading || processingView) {
			return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
		}
		return (
			<Fragment>
				<Grid container justify="center" id="taxation-list-view">
					<Grid item xs={11}>
						<Box my={5}>
							<Paper square display="flex">
                                {!taxationData ? (
                                    <Box display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                ):
                                <TaxationOverview
                                    years={yearList}
                                    onDateSelect={(event) => this.handleYear(event)}
                                    selectedYear={selectedYear}
                                    taxationData={taxationData}
                                />}
							</Paper>
						</Box>
					</Grid>
					<Grid item xs={11}>
						<div className="list-header">
							<Grid item xs={12} md={12}>
								<Box display="flex" justifyContent="flex-end" alignItems="center">
									<img className="filter-button" onClick={this.filterCliCkFun} src={require('~/assets/images/filter-icon.png')} alt="filter-icon" />
								</Box>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={11}>
						<Collapse hidden={!filterOpen} in={filterOpen}>
							<Grid container justify="space-between" hidden className="filterWrap">
                                <Grid item xs={6} sm={3}>
                                    <Box mx={1}>
                                        <label className="inputLabel">Port BU</label>
                                        <TextField
                                            fullWidth={true}
                                            autoComplete="off"
                                            variant="outlined"
                                            value={PortBu}
                                            onChange={event => this.setState({ PortBu: event.target.value })}
                                            inputProps={{
                                                ref: el => this.PortBu = el
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Officer ID</label>
                                        <TextField
                                            fullWidth={true}
                                            autoComplete="off"
                                            variant="outlined"
                                            value={Oeid}
                                            onChange={event => this.setState({ Oeid: event.target.value })}
                                            inputProps={{
                                                ref: el => this.Oeid = el
                                            }}
                                        />
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Officer Name</label>
                                        <TextField
                                            fullWidth={true}
                                            autoComplete="off"
                                            variant="outlined"
                                            value={OfficerName}
                                            onChange={event => this.setState({ OfficerName: event.target.value })}
                                            inputProps={{
                                                ref: el => this.OfficerName = el
                                            }}
                                        />
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Reward ($)</label>
                                        <CurrencyInput placeholder="" type="text"
                                            value={Rewards || ''}
                                            onChange={event => this.setState({ Rewards: event.target.value })}
                                        />
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Submitted By</label>
                                        <TextField
                                            fullWidth={true}
                                            autoComplete="off"
                                            variant="outlined"
                                            value={SubmittedBy||""}
                                            onChange={event => this.setState({ SubmittedBy: event.target.value })}
                                            inputProps={{
                                                ref: el => this.SubmittedBy = el
                                            }}
                                        />
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Status</label>
										<TextField
											fullWidth={true}
											select
											value={Status||""}
											autoComplete="off"
											variant="outlined"
											onChange={event => this.setState({ Status: event.target.value })}
											inputProps={{
												ref: el => this.status = el
											}}
										>
											{statusList ? statusList.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											)) :
												(
                                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
												)
											}
										</TextField>
									</Box>
								</Grid>
                                <Grid item xs={6} sm={3}>
                                    <Box mx={1}>
                                        <label className="inputLabel">Updated Date</label>
                                        <DatePicker
                                            customInput={<OutlinedInput
                                                variant="outlined"
                                                className="full-width"
                                                endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                                            />}
                                            className="datePicdesign dateField"
                                            selected={UpdatedDate}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            onChange={UpdatedDate => this.setState({ UpdatedDate: new Date(moment(UpdatedDate).startOf('day')) })}
                                        />
                                    </Box>
                                </Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1} display="flex" justifyContent="flex-end" alignItems="center">
										<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={() => this.clearFilter()} variant="outlined" color="primary" className="clearBtn">Clear</Button> </Link>
										{fetchingList ? (
											<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										) : (
												<Link to="#" style={{marginTop: '30px' }}> <Button onClick={() => this.getTaxationList()} variant="contained" color="primary">Search</Button> </Link>
											)}
									</Box>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
					<Grid item xs={11} md={11} lg={11} style={{ overflowX: 'auto' }}>
						<Table>
							<StyledTableHead>
								<TableRow>
									<StyledTableCell sortDirection={SortColumn === "PBU" ? SortOrder : false}>
										<TableSortLabel
											active={SortColumn === "PBU"}
											direction={SortColumn === "PBU" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("PBU")}
										>
											Port BU
                                         {SortColumn === "PBU" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
                                        <TableSortLabel
                                            active={SortColumn === "OEID"}
                                            direction={SortColumn === "OEID" ? SortOrder : 'asc'}
                                            onClick={() => this.handleSorting("OEID")}
                                        >
                                            Officer ID
                                             {SortColumn === "OEID" ? (
                                                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                    {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={SortColumn === "OfficerName"}
                                            direction={SortColumn === "OfficerName" ? SortOrder : 'asc'}
                                            onClick={() => this.handleSorting("OfficerName")}
                                        >
                                            Officer Name
                                             {SortColumn === "OfficerName" ? (
                                                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                    {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={SortColumn === "Amount"}
                                            direction={SortColumn === "Amount" ? SortOrder : 'asc'}
                                            onClick={() => this.handleSorting("Amount")}
                                        >
                                            Reward ($)
                                             {SortColumn === "Amount" ? (
                                                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                    {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={SortColumn === "STATUS"}
                                            direction={SortColumn === "STATUS" ? SortOrder : 'asc'}
                                            onClick={() => this.handleSorting("STATUS")}
                                        >
                                            Status
                                             {SortColumn === "STATUS" ? (
                                                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                    {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={SortColumn === "SubmittedBy"}
                                            direction={SortColumn === "SubmittedBy" ? SortOrder : 'asc'}
                                            onClick={() => this.handleSorting("SubmittedBy")}
                                        >
                                            Last Submitted By
                                             {SortColumn === "SubmittedBy" ? (
                                                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                    {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={SortColumn === "SubmittedDate"}
                                            direction={SortColumn === "SubmittedDate" ? SortOrder : 'asc'}
                                            onClick={() => this.handleSorting("SubmittedDate")}
                                        >
                                            Last Updated Date
                                             {SortColumn === "SubmittedDate" ? (
                                                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                    {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Action
                                    </StyledTableCell>
                                </TableRow>
							</StyledTableHead>
							<TableBody>
								{fetchingList ? (
									<TableRow>
										<TableCell colSpan={8}>
											<Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										</TableCell>
									</TableRow>
								) : (
										taxations.list && taxations.list.map((item, index) => (
											<Fragment key={item.OEID+item.PBU}>
												<StyledTableRow style={{cursor:"default"}}>
													<StyledTableCell>{item.PBU}</StyledTableCell>
													<StyledTableCell>{item.OEID}</StyledTableCell>
													<StyledTableCell>{item.OfficerName}</StyledTableCell>
													<StyledTableCell> {currency(item.Amount, { formatWithSymbol: true }).format()}</StyledTableCell>
                                                    <StyledTableCell style={{color:this.findColor(item.STATUS)}}>{item.STATUS}</StyledTableCell>
                                                    <StyledTableCell>{item.SubmittedBy}</StyledTableCell>
                                                    <StyledTableCell>{item.SubmittedDate ? moment.utc(item.SubmittedDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Link href="javascript:void(0);" className="editLinkBtn" style={{color:this.findColor(item.STATUS)}} onClick={(event) => this.handleAccountEdit(item, canSubmit, event)}>{item.SSN ? "View/Edit" : "Fill Out Form"}</Link>
                                                    </StyledTableCell>
												</StyledTableRow>
											</Fragment>
										))
									)}
							</TableBody>
							<StyledTableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[10, 25, 50, 100]}
										colSpan={8}
										count={taxations.totalCount || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										SelectProps={{
											inputProps: { 'aria-label': 'rows per page' },
											native: true,
										}}
										onChangePage={this.handlePageChange}
										onChangeRowsPerPage={this.handleRowsPerPageChange}
									/>
								</TableRow>
							</StyledTableFooter>
						</Table>
					</Grid>
                     <Grid item md={11}>
                        <Paper square display="flex" >
                            {showForm && this.displayForm(errors, formPageNo, processingView, processingEdit, processingDraft, processingSubmit, formData, canSubmit, canEditSSN)}
                        </Paper>
                    </Grid>
                    {alertMessage && this.renderAlertMessage('', alertMessage, alertMessageCallbackType)}
				</Grid>
			</Fragment>
		)
	}

	renderSnackbar = message => {
		return <Notification variant="error" message={message} />
	}

    renderAlertMessage = (title, message, callbackType) => {
        return <AlertDialog 
            dialogClassName={"alert-dialoge-root"}
            title={title} 
            message={message}
            onConfirm={() => {callbackType === 'REDIRECT' ? this.goBack() : this.hideAlertMessage() }} 
        />
    }

    displayForm = (errors, formPageNo, processingView, processingEdit, processingDraft, processingSubmit, formData, canSubmit, canEditSSN) => {
        return <TaxForm 
            title="" 
            py={5}
            canSubmit  ={canSubmit} 
            canEditSSN ={canEditSSN} 
            formPageNo ={formPageNo} 
            processingView   ={processingView} 
            processingEdit   ={processingEdit} 
            processingDraft  ={processingDraft} 
            processingSubmit ={processingSubmit}
            formData={formData} 
            onConfirm={this.handleClose} 
            clickOutsideToClose={false} 
            handleFormPageChange ={(pageNo) =>this.handleFormPageChange(pageNo)}
            handleEdit   = {(event) =>this.handleEdit(event)}
            handleDraft  = {(event) =>this.handleDraft(event)}
            handleSubmit = {(event) =>this.handleSubmit(event)}
            handleChange = {this.handleChange}
            handleCancel = {this.handleCancel}
            errors       = {errors}
            onClose={this.handleClose}
            isExistingUser={(formData && formData.OldForm) || false} />
    }
}

export default connect(state => ({ ...state.user, ...state.taxations, ...state.permissions }))(TaxationListView)