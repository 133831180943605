import React, { Component, Fragment } from 'react'
import { Box, Paper, Grid, Container, OutlinedInput, TextField, InputAdornment, Fab, Button, Dialog, DialogActions, DialogContent, CircularProgress } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp'
import DatePicker from "react-datepicker"
import moment from 'moment'
import momentTz from "moment-timezone";
import Notification from '~/components/Notification'
import { fetchPromotionDetails, updatePromotion, deletePromotion, closePromotion } from '~/redux/actions/promotion'
import { ConfirmDialog, AlertDialog } from '~/components/Dialogs'
import currency from 'currency.js';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import CurrencyInput from '~/components/CurrencyInput';
import "react-datepicker/dist/react-datepicker.css";
import './styles.scss'

class PromotionUpdateView extends Component {
    constructor(props) {
        console.log("constructor");
        super(props);
        this.state = {
            isLoading: true,
            promotionId: this.props.match.params.promotionId,
            expiredAccountFunds: 5000,
            maxFundValue: 100000000,
            open: false,
            expiredAccount: false,
            promotionDetails: null,
            showPromotionCreatedModal: false,
            showPromotionUpdatedModal: false,
            updatePromotionProgress: false,
            deletePromotionProgress: false,
            showConfirmUpdateDialog: false,
            showConfirmCloseDialog: false,
            alertMessage: null,
            alertMessageCallbackType: null,
            errors: [],
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
        const { promotionId } = this.state;
        const expiredTotal = this.props.location.state && this.props.location.state.expiredTotal;
        console.log("expired total", expiredTotal);
        this.props.dispatch(fetchPromotionDetails({ promotionId })).then(() => {
            this.setState({
                isLoading: false,
                expiredAccountFunds: expiredTotal ? expiredTotal : 0.00,
                newExpiredAccountFunds: expiredTotal ? expiredTotal : 0.00,
                promotionDetails: this.props.promotion.details,
                oldFund: (this.props.promotion.details && this.props.promotion.details.Funds) || 0.00
            })
        })
    }
    goBack = () => {
        console.log("goBack");
        this.props.history.push('/promotion')
    }
    hideAlertMessage = () => {
        console.log("hideAlertMessage");
        this.setState({
            alertMessage: null,
            alertMessageCallbackType: null,
        })
    }

    isDateValid(promotionDetails) {
        let flag = true;
        const expirationDate  = moment(promotionDetails.ExpirationDate).format("MM/DD/YYYY");
        const fulfillmentDate = moment(promotionDetails.FulfillmentDate).format("MM/DD/YYYY");

        const expirationDateTest  = moment.utc(promotionDetails.ExpirationDate).local().format('YYYY-MM-DD');
        const fulfillmentDateTest = moment.utc(promotionDetails.FulfillmentDate).local().format('YYYY-MM-DD');

        const currentDate = moment().format("YYYY-MM-DD");
        if (expirationDate.length > 10 ||
            fulfillmentDate.length > 10 ||
            isNaN(Date.parse(fulfillmentDate)) ||
            isNaN(Date.parse(expirationDate)) ||
            expirationDate === "Invalid date" ||
            fulfillmentDate === "" ||
            fulfillmentDate === "Invalid date" ||
            expirationDate === "" ||
            fulfillmentDateTest < currentDate ||
            expirationDateTest <= currentDate
            ) {
            flag = false;
        }
        return flag;
    }

    validatePromotion = promotionDetails => {
        let errors = [];
        // if (currency(promotionDetails.Funds).value < Number(promotionDetails.AllocatedFund)) {
        //     errors.push('Assigned Funds can not be less than allocated funds.');
        // }
        if (!this.isDateValid(promotionDetails) && promotionDetails.Status < 4) {
            errors.push('Please choose valid dates.');
        }
        if (new Date(promotionDetails.FulfillmentDate).getTime() >= new Date(promotionDetails.ExpirationDate).getTime()) {
            errors.push('Expiration Date must be greater than Fulfilment Date.');
        }
        if (currency(promotionDetails.Funds).value < Number(promotionDetails.AllocatedFund)) {
            errors.push('Assigned Funds can not be less than allocated funds.');
        }
        if (!promotionDetails.PromotionName || promotionDetails.PromotionName.trim() === '') {
            errors.push('Promotion Name is required.')
        }
        if (!promotionDetails.FulfillmentDate) {
            errors.push('Promotion Fulfillment Date is required.')
        }
        if (!promotionDetails.ExpirationDate) {
            errors.push('Promotion End Date is required.')
        }
        if (!promotionDetails.Funds || currency(promotionDetails.Funds).value <= 0) {
            errors.push('Assigned Funds should be more than 0.')
        }

        if (this.state.oldFunds && currency(this.state.oldFunds).value < currency(promotionDetails.Funds).value) {
            errors.push('Assigned Funds should be more than previous Fund.')
        }
        return errors
    }
    handleUpdatePromotion = async () => {
        console.log("handleUpdatePromotion");
        const { promotionDetails } = this.state
        if (promotionDetails.Status == 5) {
            console.log("handleUpdatePromotion1");
            this.handlePromotionClose()
        } else {
            console.log("handleUpdatePromotion2");
            this.setState({
                updatePromotionProgress: true
            }, () => {
                console.log("handleUpdatePromotion3");
                const errors = this.validatePromotion(promotionDetails)
                console.log("validation error", errors);
                if (errors.length) {
                    if (promotionDetails.Status == 1) {
                        if (this.isDateValid(promotionDetails)) {
                            this.setState({
                                updatePromotionProgress: false,
                                showConfirmUpdateDialog: true
                            })
                        } else {
                            this.setState({
                                errors: ["Please choose valid dates."],
                                updatePromotionProgress: false,
                            })
                        }
                    } else if (promotionDetails.Status == 2) {
                        if (this.isDateValid(promotionDetails)) {
                            this.processUpdate(2)
                        } else {
                            this.setState({
                                errors: ["Please choose valid dates."],
                                updatePromotionProgress: false
                            })
                        }

                    } else {
                        console.log("handleUpdatePromotion6");
                        this.setState({
                            errors,
                            updatePromotionProgress: false,
                        })
                    }
                } else {
                    console.log("handleUpdatePromotion7");
                    this.processUpdate(promotionDetails.Status < 3 ? 3 : promotionDetails.Status == 5 ? 6 : promotionDetails.Status)
                }
            })
        }
    }
    processUpdate = (Status) => {
        console.log("processUpdate");
        const { promotionDetails } = this.state
        console.log(promotionDetails);
        this.setState({
            updatePromotionProgress: true
        }, async () => {
            await this.props.dispatch(updatePromotion({
                ...promotionDetails,
                Status: Status || 1
            }))
            this.setState({
                updatePromotionProgress: false
            }, () => {
                if (!this.props.promotion.updateError) {
                    if (promotionDetails.Status == 1) {
                        this.setState({
                            alertMessage: 'Your Promotion was successfully created!',
                            alertMessageCallbackType: 'REDIRECT'
                        })
                    } else {
                        this.setState({
                            alertMessage: 'Your Promotion was successfully updated!',
                            alertMessageCallbackType: 'REDIRECT'
                        })
                    }
                }
            })
        })
    }
    onConfirmCreate = () => {
        this.setState({
            showConfirmUpdateDialog: false
        }, () => {
            this.processUpdate(2)
        })
    }
    onCancelCreate = () => {
        this.setState({
            showConfirmUpdateDialog: false
        }, () => {
            //this.props.history.push('/promotion')
        })
    }
    onConfirmDelete = () => {
        this.setState({
            showConfirmDeleteDialog: false
        }, () => {
            this.processDelete()
        })
    }
    onCancelDelete = () => {
        this.setState({
            showConfirmDeleteDialog: false
        })
    }
    onConfirmClose = () => {
        console.log("onConfirmClose");
        this.setState({
            showConfirmCloseDialog: false
        }, () => {
            this.processClose()
        })
    }
    onCancelClose = () => {
        console.log("onCancelClose");
        this.setState({
            showConfirmCloseDialog: false
        })
    }
    handleClose = () => {
        console.log("handleClose");
        this.setState({
            open: false,
            showPromotionUpdatedModal: false,
            showPromotionCreatedModal: false
        })
    }
    expiredAcOpen = () => {
        console.log("expiredAcOpen");
        const { promotionDetails, newExpiredAccountFunds, expiredAccountFunds, OldInvoiceValue, OldRollUpValue } = this.state;
        const { NewInvoiceValue, RollUpValue, Funds } = promotionDetails;

        let addExpiredAccountFunds = (currency(expiredAccountFunds).value - currency(RollUpValue).value);
        let UpdatedRollUpValue = RollUpValue;
        if (currency(RollUpValue).value > 0 && (currency(RollUpValue).value >= currency(Funds).value)) {
            addExpiredAccountFunds = newExpiredAccountFunds + currency(RollUpValue).value - currency(RollUpValue).value;
            UpdatedRollUpValue = currency(Funds).value;
        }

        this.setState({
            promotionDetails: {
                ...promotionDetails,
                RollUpValue: currency(UpdatedRollUpValue).value && currency(UpdatedRollUpValue).format()|| "",
            },
            expiredAccount: !this.state.expiredAccount,
            OldInvoiceValue: NewInvoiceValue,
            OldRollUpValue: currency(UpdatedRollUpValue).format(),
            newExpiredAccountFunds: addExpiredAccountFunds >= 0 ? addExpiredAccountFunds : expiredAccountFunds
        })
    }
    expiredAcClose = () => {
        console.log("expiredAcClose");
        const { expiredAccountFunds, promotionDetails, OldInvoiceValue, OldRollUpValue } = this.state;
        const newExpiredAccountFunds = (expiredAccountFunds - OldRollUpValue);

        console.log("details", promotionDetails);
        this.setState({
            promotionDetails: {
                ...promotionDetails,
                NewInvoiceValue: currency(OldInvoiceValue).format(),
                RollUpValue: OldRollUpValue && currency(OldRollUpValue).format()|| "",
            },
            addFundError: null,
            newExpiredAccountFunds: newExpiredAccountFunds >= 0 ? newExpiredAccountFunds : OldRollUpValue,
            expiredAccount: !this.state.expiredAccount
        })
    }

    handleFundsChange = (e) => {
        console.log("handleFundsChange MAx value", 100000000);
        const { OldRollUpValue, expiredAccountFunds, promotionDetails, maxFundValue } = this.state;
        const { NewInvoiceValue, RollUpValue, Funds } = promotionDetails;

        //let UpdatedFunds = e.target.value === '' ? e.target.value : parseFloat(e.target.value, 2),
        let UpdatedFunds = e.target.value,
            //let UpdatedFunds = currency(e.target.value).value,
            UpdatedNewInvoiceValue = currency(NewInvoiceValue).value,
            UpdatedRollUpValue = currency(RollUpValue).value,
            totalAddition = 0,
            totalDeduction = 0,
            addExpiredAccountFunds = expiredAccountFunds - UpdatedRollUpValue;

        if ((currency(UpdatedFunds).value <= maxFundValue) || UpdatedFunds === '') {
            if (currency(UpdatedFunds).value > currency(RollUpValue).value) {
                console.log("1")
                UpdatedNewInvoiceValue = currency(UpdatedFunds).value - (currency(RollUpValue).value || 0);
            } else {
                console.log("2")
                if (currency(RollUpValue).value > 0 && (currency(addExpiredAccountFunds).value >= currency(UpdatedFunds).value)) {
                    addExpiredAccountFunds = addExpiredAccountFunds + currency(RollUpValue).value - currency(UpdatedFunds).value;
                    //UpdatedRollUpValue = currency(UpdatedFunds).value;
                } else {
                    console.log("3")
                    //UpdatedRollUpValue = currency(UpdatedFunds).value;
                    addExpiredAccountFunds = expiredAccountFunds - currency(RollUpValue).value;
                }
                UpdatedNewInvoiceValue = 0;
            }
            this.setState({
                promotionDetails: {
                    ...promotionDetails,
                    Funds: UpdatedFunds,
                    RollUpValue: currency(UpdatedRollUpValue).format(),
                    NewInvoiceValue: currency(UpdatedNewInvoiceValue).format()
                },
                OldRollUpValue:UpdatedRollUpValue,
                newExpiredAccountFunds: addExpiredAccountFunds
            })

        } else {
            // this.setState({
            //     promotionDetails: {
            //         ...promotionDetails,
            //         Funds: e.target.value,
            //         RollUpValue: 0,
            //         NewInvoiceValue: 0
            //     }
            // })
        }
    }

    addFundValue = () => {
        console.log("addFundValue");
        const { promotionDetails, expiredAccountFunds, maxFundValue } = this.state;
        const { NewInvoiceValue, RollUpValue } = promotionDetails;

        const UpdatedNewInvoiceValue = currency(NewInvoiceValue).value || 0;
        const UpdatedRollUpValue = currency(RollUpValue).value || 0;
        const UpdatedFunds = (UpdatedNewInvoiceValue + UpdatedRollUpValue);
        console.log("rollup amount", UpdatedRollUpValue);
        if ((UpdatedRollUpValue) > currency(expiredAccountFunds).value) {
            this.setState({
                addFundError: 'Funds from expired account cannot be more than available funds.',
            })
        } else if ((UpdatedRollUpValue) + (UpdatedNewInvoiceValue) > maxFundValue) {
            this.setState({
                addFundError: `Total Funds can not be greater than ${maxFundValue}.`,
            })
        } else {
            console.log("funds", UpdatedFunds);
            this.setState({
                expiredAccount: false,
                addFundError: null,
                addFundProcessing: false,
                promotionDetails: {
                    ...promotionDetails,
                    Funds: currency(UpdatedFunds).format(),
                    RollUpValue: currency(UpdatedRollUpValue).format(),
                    NewInvoiceValue: currency(UpdatedNewInvoiceValue).format()
                }
            })

        }

    }
    handlePromotionDelete = () => {
        console.log("handlePromotionDelete");
        this.setState({
            showConfirmDeleteDialog: true
        })
    }
    handlePromotionClose = () => {
        console.log("handlePromotionClose");
        this.setState({
            showConfirmCloseDialog: true
        })
    }
    processDelete = () => {
        console.log("processDelete");
        const { promotionDetails } = this.state
        this.setState({
            showConfirmDeleteDialog: false,
            deletePromotionProgress: true
        }, async () => {
            await this.props.dispatch(deletePromotion(promotionDetails.PromotionID))
            if (!this.props.promotion.updateError) {
                this.props.history.push('/promotion')
            } else {
                this.setState({
                    deletePromotionProgress: false
                })
            }
        })
    }
    processClose = () => {
        console.log("processClose");
        const { promotionDetails } = this.state
        this.setState({
            showConfirmDeleteDialog: false,
            updatePromotionProgress: true
        }, async () => {
            await this.props.dispatch(closePromotion(promotionDetails.PromotionID))
            if (!this.props.promotion.updateError) {
                this.props.history.push('/promotion')
            } else {
                this.setState({
                    deletePromotionProgress: false
                })
            }
        })
    }

    //Prevent date edit from input field
    handleDateChangeRawFulfilment = (e, promotionDetails) => {
        if(e.target.value.length >10) {
            e.preventDefault();
            return false;
        }

        const validDateFormat = /^[A-Za-z]/;
        if(e.target.value.match(validDateFormat)) {
            e.preventDefault();
            return false;
        }

        const isDateValid = this.isManualDateValid(e.target.value);
        if(!isDateValid) {
            if (!this.isDateValid(promotionDetails)) {
                this.setState({ errors: ["Please choose valid dates."] });
            }
            //e.preventDefault();
            //return false;
        } else {
            this.setState({ errors: [] });
            return true;
        }
    }

    handleDateChangeRawExpiration = (e, promotionDetails) => {
        if(e.target.value.length >10) {
            e.preventDefault();
            return false;
        }

        const validDateFormat = /^[A-Za-z]/;
        if(e.target.value.match(validDateFormat)) {
            e.preventDefault();
            return false;
        }

        const isDateValid = this.isManualDateValid(e.target.value);
        if(!isDateValid) {
            if (!this.isDateValid(promotionDetails)) {
                this.setState({ errors: ["Please choose valid dates."] });
            }
            //e.preventDefault();
            //return false;
        } else {
            this.setState({ errors: [] });
            return true;
        }
    }

    isManualDateValid(value, isEndDate) {
        let flag = true;

        //Date format check
        const validDateFormat = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

        if(!value.match(validDateFormat)) {
           return false;
        }

        const valueDate = moment(value).format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");
        if (isNaN(Date.parse(valueDate)) ||
            valueDate === "Invalid date" ||
            (isEndDate && valueDate == currentDate)||
            valueDate < currentDate ||
            valueDate === "") {
            flag = false;
        }
        return flag;
    }

    handleFulfilmentDate = (date, PromotionDetails) => {
        console.log("handleFulfilmentDate");
        const selectDate = moment(date).format("MM/DD/YYYY");
        const isDateValid = this.isManualDateValid(selectDate);
        if(!isDateValid) {
            this.setState({ errors: ["Please choose valid dates."] });
            //return false;
        } else {
            this.setState({ errors: [] });
            //return true;
        }

        console.log("full", selectDate);
        this.setState({
            promotionDetails: {
                ...PromotionDetails,
                FulfillmentDate: moment(moment(date)).utc().format("MM/DD/YYYY HH:mm:ss"),
                ExpirationDate: moment(moment(date).add(6, 'months')).utc().format("MM/DD/YYYY HH:mm:ss")
            }
        });
    }

    handleExpirationDate = (date, PromotionDetails) => {
        console.log("handleExpirationDate");
        const selectDate = moment(date).format("MM/DD/YYYY");
        const isDateValid = this.isManualDateValid(selectDate, true);
        if(!isDateValid) {
            this.setState({ errors: ["Please choose valid dates."] });
            //return false;
        } else {
            this.setState({ errors: [] });
            //return true;
        }

        this.setState({
            promotionDetails: {
                ...PromotionDetails,
                ExpirationDate: moment(moment(date)).utc().format("MM/DD/YYYY HH:mm:ss"),
            }
        })
    }

    render() {
        const { newExpiredAccountFunds, isLoading, addFundProcessing, alertMessage, alertMessageCallbackType, deletePromotionProgress, addFundError, errors, updatePromotionProgress, showConfirmCloseDialog, showConfirmDeleteDialog, showPromotionUpdatedModal, showConfirmUpdateDialog, showPromotionCreatedModal, open, expiredAccount, promotionDetails, expiredAccountFunds, maxFundValue } = this.state;
        const { user, promotion } = this.props;
        if (isLoading) {
            return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
        }
        return (
            <Fragment>
                <div id="updateViewContainer">
                    <Container>
                        <Paper square className="updateContainer">
                            <Grid container>
                                <Grid item md={12}>
                                    <Box display="flex" justifyContent="space-between">
                                        <div className="HeadingPromotion">{promotionDetails.Status == 1 ? 'Create Promotion' : 'Edit Promotion'}</div>
                                        {(promotionDetails.IsDelete == 1) && (
                                            <Box color="error.main" onClick={() => this.handlePromotionDelete()}>
                                                {deletePromotionProgress ? (
                                                    <Box width="100px" justifyContent="center" alignItems="center"><CircularProgress color="error" /></Box>
                                                ) : (
                                                        <DeleteOutlineSharpIcon />
                                                    )}
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                {errors.length > 0 && (
                                    <Grid item md={12}>
                                        <Box py={1} px={1} mb={1} color="error.main">
                                            <ul className="error">
                                                {errors.map(err => <li key={err}>{err}</li>)}
                                            </ul>
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item md={3} xs={12}>
                                    <div className="ProText">Promotion ID  </div>
                                    <div className="ProIdNum" name="PromotionID">{promotionDetails.PromotionID ? promotionDetails.PromotionID : ''}</div>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <label className="inputLabel">Promotion Name</label>
                                    <TextField
                                        disabled={parseInt(promotionDetails.Status) > 3}
                                        variant="outlined"
                                        className="inputFieldW100"
                                        value={promotionDetails.PromotionName}
                                        onChange={e => {
                                            const letterNumber = /^[0-9a-zA-Z\s]+$/;
                                            if (e.target.value.match(letterNumber) || e.target.value == "") {
                                                this.setState({
                                                    promotionDetails: {
                                                        ...promotionDetails,
                                                        PromotionName: e.target.value
                                                    }
                                                })
                                            }
                                        }}
                                        inputProps={{
                                            ref: el => this.PromotionName = el
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <div className="ProText">Status</div>
                                    <div className="ProStaus">{promotionDetails.PortalStatusName}</div>
                                </Grid>
                                <Grid item md={3}>
                                    <label className="inputLabel">Fulfilment Date</label>
                                    <DatePicker
                                        onChangeRaw={(e, date) => this.handleDateChangeRawFulfilment(e, promotionDetails)}
                                        onSelect={(date) => this.handleFulfilmentDate(date, promotionDetails)}
                                        disabled={parseInt(promotionDetails.Status) > 3}
                                        selected={promotionDetails && promotionDetails.FulfillmentDate && new Date(moment.utc(promotionDetails.FulfillmentDate).local().format("MM/DD/YYYY HH:mm:ss")).getTime()}
                                        customInput={<OutlinedInput
                                            variant="outlined"
                                            className="full-width"
                                            endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                                        />}
                                        >
                                    </DatePicker>
                                </Grid>
                                <Grid item md={3}>
                                    <label className="inputLabel">End Date</label>
                                    <DatePicker
                                        onChangeRaw={(e) => this.handleDateChangeRawExpiration(e, promotionDetails)}
                                        disabled={parseInt(promotionDetails.Status) > 3}
                                        minDate={new Date(moment(promotionDetails.FulfillmentDate).add(1, 'days'))}
                                        selected={promotionDetails && promotionDetails.ExpirationDate && new Date(moment.utc(promotionDetails.ExpirationDate).local().format("MM/DD/YYYY HH:mm:ss")).getTime()}
                                        customInput={<OutlinedInput
                                            variant="outlined"
                                            className="full-width"
                                            endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                                        />}
                                        onSelect={date => this.handleExpirationDate(date, promotionDetails)}
                                    >
                                    </DatePicker>
                                </Grid>
                                <Grid item md={12}>
                                    <div className="HeadingFunds">Add Funds</div>
                                </Grid>
                                <Grid item md={3}>
                                    <label className="inputLabel">Assigned Fund Value</label>
                                    <CurrencyInput placeholder=""
                                        type="text"
                                        onPaste={(e) => e.preventDefault()}
                                        onCopy={(e) => e.preventDefault()}
                                        className="fund-input"
                                        disabled={parseInt(promotionDetails.Status) > 4}
                                        value={promotionDetails.Funds || ''}
                                        currencySymbol="$"
                                        onChange={(event) => this.handleFundsChange(event)}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <Box mt={1.5} className="full-height flex-container vertical-center horizontal-center">
                                        <Fab size="small" color="primary" aria-label="add" onClick={parseInt(promotionDetails.Status) > 4 ? () => null : this.expiredAcOpen}>
                                            <AddIcon />
                                        </Fab>
                                        <Box mx={1}>Add from Expired Account</Box>
                                    </Box>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Link to="/promotion">
                                            <Button variant="contained" color="secondary" className="btnCancel">Cancel</Button>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        {updatePromotionProgress ? (
                                            <Box width="100px" display="flex" mt={4} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                        ) : (
                                                <Button variant="contained" color="primary" className="btnCreate" onClick={this.handleUpdatePromotion}>
                                                    {(promotionDetails.Status == 1) ? `Create Promotion` : (promotionDetails.Status == 5) ? `Close Promotion` : `Update Promotion`}
                                                </Button>
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </div>
                <Dialog
                    open={expiredAccount}
                    onClose={this.expiredAcClose}
                    aria-labelledby="expired-account"
                    aria-describedby="expired-account-description"
                    className="promotionCreate"
                >
                    <DialogTitle id="expired-account-title">
                        <Box px={4} mt={2}>Add from Expired Account</Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box px={4}>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <div className="expiredAmount">{currency(newExpiredAccountFunds, { formatWithSymbol: true }).format()}</div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className="noramlText">Available in Expired Account </div>
                                </Grid>
                                <hr style={{ width: '100%' }} />
                                <Grid item md={12}>
                                    <div className="noramlText">Choose the account from where you want to assign the funds to this promotion</div>
                                </Grid>

                                <Grid item md={6}>
                                    <label className="inputLabel">Expired Amount</label>
                                    <CurrencyInput placeholder=""
                                        type="text"
                                        className="fund-input"
                                        disabled={!expiredAccountFunds}
                                        value={promotionDetails.RollUpValue || ""}
                                        currencySymbol="$"
                                        onChange={e => {
                                            if ((currency(e.target.value).value <= maxFundValue) || e.target.value === '') {
                                                const newExpiredAccountFunds = expiredAccountFunds - currency(e.target.value).value;
                                                console.log("changes funds value", newExpiredAccountFunds);
                                                //if(newExpiredAccountFunds >=0) {
                                                this.setState({
                                                    promotionDetails: {
                                                        ...promotionDetails,
                                                        RollUpValue: e.target.value
                                                    },
                                                    newExpiredAccountFunds: newExpiredAccountFunds,
                                                })
                                                //} else {
                                                // this.setState({
                                                //      newExpiredAccountFunds : expiredAccountFunds,
                                                // })
                                                //}
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <label className="inputLabel">New Invoice</label>
                                    <CurrencyInput placeholder=""
                                        type="text"
                                        onPaste={(e) => e.preventDefault()}
                                        onCopy={(e) => e.preventDefault()}
                                        className="fund-input"
                                        value={promotionDetails.NewInvoiceValue || ""}
                                        currencySymbol="$"
                                        onChange={e => {
                                            if ((currency(e.target.value).value <= maxFundValue) || e.target.value === '') {
                                                this.setState({
                                                    promotionDetails: {
                                                        ...promotionDetails,
                                                        NewInvoiceValue: e.target.value
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box px={4} mb={5} mx={2} className="full-width">
                            {addFundError && (
                                <Box mb={2} className="full-width" color="error.main">{addFundError}</Box>
                            )}
                            <Box display="flex" justifyContent="space-between" className="full-width">
                                <Button variant="contained" color="secondary" onClick={() => this.expiredAcClose()} autoFocus>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={() => this.addFundValue()} color="primary" autoFocus>
                                    Add
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </Dialog>
                {promotion.updateError && this.renderSnackbar(promotion.updateError)}
                {showConfirmUpdateDialog && this.renderUpdateDialog('You do not have all the fields entered to create a promotion', 'Do you want to save this as draft?')}
                {showConfirmDeleteDialog && this.renderDeleteDialog('Are you sure you want to delete this promotion', '')}
                {showConfirmCloseDialog && this.renderCloseDialog('Are you sure you want to close this promotion', '')}
                {alertMessage && this.renderAlertMessage('', alertMessage, alertMessageCallbackType)}
            </Fragment>
        )
    }
    statusText = statusId => {
        switch (parseInt(statusId)) {
            case 1:
                return 'Draft'
            case 2:
                return 'Draft'
            case 3:
                return 'Open'
            case 4:
                return 'In Progress'
            case 5:
                return 'Expired'
            case 6:
                return 'Closed'
        }
    }
    renderSnackbar = message => {
        return <Notification variant="error" message={message} />
    }
    renderUpdateDialog = (title, message) => {
        return <ConfirmDialog title={title} message={message} onCancel={this.onCancelCreate} onConfirm={this.onConfirmCreate} />
    }
    renderDeleteDialog = (title, message) => {
        return <ConfirmDialog title={title} message={message} onCancel={this.onCancelDelete} onConfirm={this.onConfirmDelete} />
    }
    renderCloseDialog = (title, message) => {
        return <ConfirmDialog title={title} message={message} onCancel={this.onCancelClose} onConfirm={this.onConfirmClose} />
    }
    renderAlertMessage = (title, message, callbackType) => {
        return <AlertDialog dialogClassName="promotionCreate" title={title} message={message} onConfirm={callbackType === 'REDIRECT' ? this.goBack : this.hideAlertMessage()} />
    }
}

export default connect(state => (
    { ...state.user, ...state.promotion }
))(PromotionUpdateView)