import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@material-ui/core";
import "./styles.scss";
import UserManagementList from "../UserManagementList/index";
import NotificationManagementList from "../NotificationManagementList/index";
import Configuration from "../Configuration/index";
import { TabPanel, a11yProps } from "../../../components/TabPanel/index";
import { Grid, Button } from "@material-ui/core";
import { fetchUsersList } from "~/redux/actions/settings";
import { connect } from "react-redux";
import Notifier from "../../../components/Notifier";
import { getUserTypes } from "~/redux/helpers/user.js";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { fetchReconciliationReport } from '~/redux/actions/reports';

class TabView extends React.Component {
    state = {
        value: 0,
        notifierMessage: "",
        notifierOpen: false,
        userTypes: [],
        downloadProgress:false,
    };

    componentDidMount = async () => {
        const response = await getUserTypes({ RoleId: 1 });
        if (response.result && !response.ErrorMessage) {
          this.setState({ userTypes: response.result });
        } else {
          alert("Something went wrong.");
        }
    }

  handleNotifierClose = () => {
    this.setState({ notifierMessage: "", notifierOpen: false });
  }

  openNotifier = (message) => {
    this.setState({ notifierMessage: message, notifierOpen: true });
  }

  handleDownload =(e) =>{
    console.log("download api call")
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileName = "user-summary-report";
    const fileExtension = '.xlsx';
 
    this.setState(
        {downloadProgress:true},
            () => {
               this.props.fetchUsersList ({ clientId: 1, IsDownload: 1}).then((response) => {
                    if (!response) {
                        return false;
                    }
                 if (response.userDto) {
                   const sheetData = response.userDto.map(data => {
                     return {
                       Name: `${data.FirstName +" "+ data.LastName}`,
                       UserId: (data.SSOUserId ? data.SSOUserId : data.UserName),
                       Access: data.UserType,
                       PhoneNumber: data.PhoneNumber,
                       EmailAddress: data.EmailAddress,
                       IsActive: data.IsActive
                     }
                      })
                      const ws = XLSX.utils.json_to_sheet(sheetData);
                      const wb = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, ws, 'User Summary Report');
                      const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
                      const dataExcel = new Blob([excelBuffer], { type: fileType });
                      FileSaver.saveAs(dataExcel, fileName + fileExtension);
 
                      this.setState({downloadProgress:false, error:false});
                    }
                    else {
                      //error message
                      this.setState({
                          downloadProgress:false, 
                          error:this.props.users.list? false :true});
                  }
                })
 
            // }
 
        }
    );
}

  render() {
    const { value, notifierMessage, notifierOpen, userTypes, downloadProgress } = this.state;
    const { users, fetchUsersList, user, claims } = this.props;
    const canViewCongigurationTab = claims && claims.includes("configuration_view");

    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };
    return (
      <div>
        <Grid container justify="center" className="settingsWrapper">
          <Grid item xs={11}>
            <Box my={5}>
              <AppBar position="static" className="tabHeader">
                <Grid container>
                  <Grid xs={7}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="User Management" {...a11yProps(0)} />
                      {canViewCongigurationTab && <Tab label="Configuration" {...a11yProps(1)} />}
                    </Tabs>
                  </Grid>
                  {value==0 &&<Grid xs={5}>
                    <div className="btnContainer">
                      <Button
                        variant="contained"
                        className="btns"
                        color="primary"
                        onClick={() =>
                          this.props.history.push("/settings/addUser")
                        }
                      >
                        Add User
                      </Button>
                      {downloadProgress?  <CircularProgress color="primary" />:<Button
                        variant="contained"
                        className="btns"
                        color="primary"
                        onClick={this.handleDownload}
                      >
                        Download
                      </Button>}
                    </div>
                  </Grid>}  
                </Grid>
              </AppBar>

              <div className="tabContent">
                <TabPanel value={value} index={0}>
                    <UserManagementList users={users} myDetails={user} fetchUsersList={fetchUsersList} userTypes={userTypes} openNotifier={(message) => this.openNotifier(message)} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Configuration renewalDate={fetchUsersList} />
                </TabPanel>
                {/*<TabPanel value={value} index={1}>
                  <NotificationManagementList users={users} fetchUsersList={fetchUsersList} userTypes={userTypes} openNotifier={(message) => this.openNotifier(message)} />
                      </TabPanel>*/}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Notifier message={notifierMessage} open={notifierOpen} handleClose={this.handleNotifierClose} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  user: state.user
});

export default connect(mapStateToProps, {
  fetchUsersList
})(TabView);
