import React, { Component, Fragment } from 'react'
import { Paper, Grid, Container, TextField, Fab, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.scss'

export default class PromotionEditView extends Component {
    state = {
        startDate: new Date()
    };

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };



    render() {

        return (
            <Fragment>
                <div id="updateViewContainer">
                    <Container>
                        <Paper square className="updateContainer">
                            <Grid container>
                                <Grid item md={12}>
                                    <div className="HeadingPromotion"> Edit Promotion </div>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <div className="ProText"> Promotion ID  </div>
                                    <div className="ProIdNum"> 410  </div>
                                </Grid>
                                <Grid item md={7} xs={12}> <TextField label="Promotion Name" variant="outlined" className="inputFieldW100"></TextField> </Grid>
                                <Grid item md={2} xs={12}> <div className="ProText"> Status </div>
                                    <div className="ProStaus"> DRAFT  </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div className="ProText"> Fulfilment Date  </div>
                                    <DatePicker className="datePicdesign"
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <div className="ProText"> End Date  </div>
                                    <DatePicker className="datePicdesign"
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item md={12}>
                                    <div className="HeadingPromotion padTop24">Add Funds </div>
                                </Grid>
                                <Grid item md={3}> <TextField label="Allocated Fund Value" variant="outlined" fullWidth={true}></TextField></Grid>
                                <Grid item md={3}>
                                    <Fab size="small" color="primary" aria-label="add" > <AddIcon /> </Fab>  Add from Expired Account
                            </Grid>
                                <Grid container>
                                    {/* <Grid item md={12}> <Button className="btnDel">  DELETE </Button> </Grid> */}
                                    <Grid item> <Button variant="outlined" className="btnCancel">  Cancel </Button>    </Grid>
                                    <Grid item> <Button variant="contained" color="primary" className="btnCreate">  Update Promotion </Button></Grid>
                                </Grid>
                            </Grid>


                        </Paper>
                    </Container>
                </div>
            </Fragment>
        )
    }
}