import React, { Component, Fragment } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { Link } from 'react-router-dom';
import _ from 'lodash';
//import NavData from '~/config/navigation.json'
import './styles.scss'

export default class NavBar extends Component {

    state = {
        leftMenu: [
            {
                "url": "/overview",
                "name": "Overview",
                "items": [],
                "alias": "overview",
                "isProtected": true
            },
            {
                "url": "/promotion",
                "name": "Promotions",
                "items": [],
                "alias": "promotion",
                "isProtected": true
            },
            {
                "url": "/rewards",
                "name": "Rewards",
                "items": [],
                "alias": "rewards",
                "isProtected": true
            },
            {
                "url": "/reports",
                "name": "Reports",
                "alias": "reports",
                "items": [],
                "isProtected": true
            },
            {
                "url": "/taxation",
                "name": "Taxation",
                "alias": "w9form",
                "items": [],
                "isProtected": true
            },
            {
                "url": "/support",
                "name": "Support",
                "alias": "support",
                "items": [],
                "isProtected": true
            }
        ]
    };

    isViewable(name, isProtected) {
        if (isProtected) {
            const { claims } = this.props;
            let str = `${name && name.toLowerCase()}_view`;
            let isEnabled = claims && claims.includes(str);
            if (isEnabled) {
                return true
            }
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { leftMenu } = this.state
        const { path } = this.props.match
        const currentNavIndex = _.findIndex(leftMenu, item => item.url === path)
        return (
            <Fragment>
                <div id="navbar">
                    <Tabs value={currentNavIndex} indicatorColor="primary">
                        {leftMenu.map((navItem, index) => (
                            <span key={index}>
                                {this.isViewable(navItem.alias, navItem.isProtected) === true ?
                                    <Link to={navItem.url} key={index}>
                                        <Tab label={navItem.name} />
                                    </Link> : null}
                            </span>
                        ))}
                    </Tabs>
                </div>
            </Fragment>
        )
    }
}