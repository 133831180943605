import React, { Component, Fragment } from 'react'
import { CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'


class PromotionDetailView extends Component {
	state = {
		isLoading: true
	}
	componentDidMount() {
		this.setState({
			isLoading: false
		})
	}
	render() {
		const { isLoading } = this.state
		if (isLoading) {
			return <CircularProgress color="primary" />
		}
		return (
			<Fragment>

			</Fragment>
		)
	}
}

export default connect(state => ({ ...state.user }))(PromotionDetailView)