import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user';

let UserPermissions = null;

export const getUserPermission = async (data) => {
  const accessToken = await getAccessToken();
  try {
    const response = await axios({
      url: `${config.base_url}/api/Settings/GetUserPermissionById`,
      //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/GetUserPermissionById`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${accessToken}`,
      },
      data: JSON.stringify(data)
    })

    return response.data;
  } catch (error) {
    return {
      error: (error.response && error.response.data.error) || 'Something went wrong!'
    }
  }
}

export const getUserPermissionsMinified = async (data) => {
  const accessToken = await getAccessToken();
  try {
    if (!UserPermissions) {
      const response = await axios({
        url: `${config.base_url}/api/Settings/GetClaimsByUserId`,
        //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/GetClaimsByUserId`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${accessToken}`,
        },
        data: JSON.stringify(data)
      });
      UserPermissions = response.data;
      return response.data;
    } else {
      return UserPermissions;
    }

  } catch (error) {
    return {
      error: (error.response && error.response.data.error) || 'Something went wrong!'
    }
  }
}

export const updateUserPermission = async (data) => {
  const accessToken = await getAccessToken();
  try {
    const response = await axios({
      url: `${config.base_url}/api/Settings/UpdateUserPermission`,
      //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/UpdateUserPermission`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${accessToken}`,
      },
      data: JSON.stringify(data)
    })
    return await response.data
  } catch (error) {
    return {
      error: (error.response && error.response.data.error) || 'Something went wrong!'
    }
  }
}

export const setUserPermissions = async (data) => {
  const accessToken = await getAccessToken();
  try {
    const response = await axios({
      url: `${config.base_url}/api/Settings/UpdateUserPermission`,
      //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/UpdateUserPermission`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${accessToken}`,
      },
      data: JSON.stringify(data)
    })
    return await response.data
  } catch (error) {
    return {
      error: (error.response && error.response.data.error) || 'Something went wrong!'
    }
  }
}
