var initialState = {
	user: {
		isLoggedIn: false,
		error: null,
		updatePasswordError: null,
		info: {}
	},
}

export default function user(state = initialState, action = {}) {
	switch (action.type) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
				user: {
					info: {
						...state.user.info,
						...action.payload
					},
					error: null,
					isLoggedIn: true,
				}
			}
		case 'LOGIN_FAILED':
			return {
				...state,
				user: {
					error: action.payload.error,
					info: {},
					isLoggedIn: false,
				}
			}
		case 'UPDATE_PASSWORD_SUCCESS':
			return {
				...state,
				user: {
					...state.user,
					info: {
						...state.user.info,
						...action.payload
					},
					error: null,
				}
			}
		case 'UPDATE_PASSWORD_FAILED':
			return {
				...state,
				user: {
					...state.user,
					error: action.payload,
				}
			}
		case 'LOGOUT_SUCCESS':
			return {
				...state,
				user: {
					isLoggedIn: false,
					info: {},
					error: null,
				}
			}
		default:
			return {
				...state
			}
	}
}