import React from 'react';

const UpdatedForm4 = (props) => {

  return (
    <div id="page4">
      <div
        style={{
          clear: "both",
          width: "100%",
          fontSize: "7pt",
          paddingTop: "4px",
          paddingBottom: "4px",
          borderTop: "2px solid black",
          borderBottom: "2px solid black"
        }}
      >
        <span>Form W-9 (Rev. 3-2024)</span>
        <span style={{ marginLeft: "620px" }}>Page{" "}<span style={{ fontSize: "10pt", fontWeight: "bold" }}> 4</span></span>
      </div>
      <div style={{ clear: "both", width: "100%", fontSize: "8pt" }}>
        <div style={{ float: "left", width: "380px" }}>
          <ol start="2"
            style={{
              listStyle: "decimal inside ",
              margin: "0",
              padding: " 0"
            }}
          >
            <li>
              —The United States or any of its agencies or instrumentalities.
            </li>
            <li>
              — A state, the District of Columbia, a U.S. commonwealth or territory,
              or any of their political subdivisions or instrumentalities.
            </li>
            <li>
              — A foreign government or any of its political subdivisions, agencies,
              or instrumentalities.
            </li>

            <li>— A corporation</li>
            <li>
              — A dealer in securities or commodities required to register in the
              United States, the District of Columbia, or a U.S. commonwealth or
              territory.
            </li>
            <li>
              — A futures commission merchant registered with the Commodity
              Futures Trading Commission.
            </li>
            <li>—A real estate investment trust. </li>
            <li>
              —An entity registered at all times during the tax year under the
              Investment Company Act of 1940.
            </li>
            <li>
              — A common trust fund operated by a bank under section 584(a).
            </li>
            <li>— A financial institution as defined under section 581.</li>
            <li>
              — A middleman known in the investment community as a nominee or
              custodian.
            </li>
            <li>
              — A trust exempt from tax under section 664 or described in section
              4947.
            </li>
          </ol>
          <p>
            The following chart shows types of payments that may be exempt
            from backup withholding. The chart applies to the exempt payees listed
            above, 1 through 13
          </p>

          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              borderTop: "solid 1px black",
              fontSize: "8pt",
              width: "380px"
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    fontWeight: "bold",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top",

                    width: "189px"
                  }}
                >
                  IF the payment is for . . .
                </td>
                <td
                  style={{
                    borderBottom: "1px solid black",
                    fontWeight: "bold",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    verticalAlign: "top",

                    width: "190px"
                  }}
                >
                  THEN the payment is exempt
                  <br />
                  for . . .
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    maxWidth: "1px"
                  }}
                >
                  <div style={{ clear: "both" }}>
                    <div>• Interest and dividend payments</div>
                  </div>
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    borderBottom: "1px solid black"
                  }}
                >
                  All exempt payees except for 7
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    maxWidth: "1px"
                  }}
                >
                  <div style={{ clear: "both" }}>
                    <div>• Broker transactions</div>
                  </div>
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    borderBottom: "1px solid black"
                  }}
                >
                  Exempt payees 1 through 4 and 6 through 11 and all C
                  corporations. S corporations must not enter an exempt
                  payee code because they are exempt only for sales of
                  noncovered securities acquired prior to 2012.
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    maxWidth: "1px"
                  }}
                >
                  <div style={{ clear: "both" }}>
                    <div>
                      • Barter exchange transactions and patronage dividends
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    borderBottom: "1px solid black"
                  }}
                >
                  Exempt payees 1 through 4
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    maxWidth: "1px"
                  }}
                >
                  <div style={{ clear: "both" }}>
                    <div>
                      • Payments over $600 required to be reported and
                      direct sales over $5,0001
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    borderBottom: "1px solid black"
                  }}
                >
                  Generally, exempt payees 1 through 5<sup>2</sup>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    maxWidth: "1px"
                  }}
                >
                  <div style={{ clear: "both" }}>
                    <div>
                      Payments made in settlement of payment card or third-
                      party network transactions
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    verticalAlign: "top",
                    borderBottom: "1px solid black"
                  }}
                >
                  Exempt payees 1 through 4
                </td>
              </tr>
            </tbody>
          </table>
          <p
            style={{
              marginTop: "2px",
              marginBottom: "0px",
              fontSize: "7pt"
            }}
          >
            <sup>1</sup> See Form 1099-MISC, Miscellaneous Information, and its instructions.
          </p>
          <p
            style={{
              marginTop: "2px",
              marginBottom: "0px",
              fontSize: "7pt"
            }}
          >
            <sup>2</sup> However, the following payments made to a
            corporation and reportable on Form 1099-MISC are not exempt
            from backup withholding: medical and health care payments,
            attorneys’ fees, gross proceeds paid to an attorney reportable
            under section 6045(f), and payments for services paid by a
            federal executive agency.
          </p>
          <p
            style={{
              marginTop: "5px",
              marginBottom: "0px",
              fontSize: "7pt"
            }}
          >
            <b>Exemption from FATCA reporting code.</b> The following codes identify
            payees that are exempt from reporting under FATCA. These codes
            apply to persons submitting this form for accounts maintained outside
            of the United States by certain foreign financial institutions. Therefore, if
            you are only submitting this form for an account you hold in the United
            States, you may leave this field blank. Consult with the person
            requesting this form if you are uncertain if the financial institution is
            subject to these requirements. A requester may indicate that a code is
            not required by providing you with a Form W-9 with “Not Applicable” (or
            any similar indication) entered on the line for a FATCA exemption code.
          </p>

          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            A — An organization exempt from tax under section 501(a) or any
            individual retirement plan as defined in section 7701(a)(37).
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            B — The United States or any of its agencies or
            instrumentalities
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            C—A state, the District of Columbia, a U.S. commonwealth or
            territory, or any of their political subdivisions or instrumentalities
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            D—A corporation the stock of which is regularly traded on one or
            more established securities markets, as described in Regulations
            section 1.1472-1(c)(1)(i).
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            E—A corporation that is a member of the same expanded affiliated
            group as a corporation described in Regulations section 1.1472-1(c)(1)(i).{" "}
          </p>
        </div>
        <div
          style={{ float: "left", width: "380px", marginLeft: "20px" }}
        >
          <p style={{ padding: "0px", margin: "5px 0" }}>
            F—A dealer in securities, commodities, or derivative financial
            instruments (including notional principal contracts, futures, forwards,
            and options) that is registered as such under the laws of the United
            States or any state{" "}
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            G—A real estate investment trust
          </p>
          <p style={{ padding: "0px" }}>
            {" "}
            H—A regulated investment company as defined in section 851 or an
            entity registered at all times during the tax year under the Investment
            Company Act of 1940.
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            I—A common trust fund as defined in section 584(a).
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            J—A bank as defined in section 581.
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}> K—A broker. </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            {" "}
            L—A trust exempt from tax under section 664 or described in
            section 4947(a)(1).
          </p>
          <p style={{ padding: "0px", margin: "5px 0" }}>
            M—A tax-exempt trust under a section 403(b) plan or section
            457(g) plan.
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <b>Note:</b> You may wish to consult with the financial institution requesting
            this form to determine whether the FATCA code and/or exempt payee
            code should be completed.
          </p>
          <p
            style={{
              fontSize: "10pt",
              fontWeight: "bold",
              margin: "4px 0"
            }}
          >
            Line 5
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            Enter your address (number, street, and apartment or suite number).
            This is where the requester of this Form W-9 will mail your information
            returns. If this address differs from the one the requester already has on
            file, enter “NEW” at the top. If a new address is provided, there is still a
            chance the old address will be used until the payor changes your
            address in their records.
          </p>
          <p
            style={{
              fontSize: "10pt",
              fontWeight: "bold",
              margin: "4px 0"
            }}
          >
            Line 6
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            Enter your city, state, and ZIP code
          </p>
          <p
            style={{
              marginTop: "7px",
              marginBottom: "0px",
              fontWeight: "bold",
              fontSize: "13pt"
            }}
          >
            Part I. Taxpayer Identification Number (TIN)
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <b>Enter your TIN in the appropriate box. </b> If you are a resident alien and
            you do not have, and are not eligible to get, an SSN, your TIN is your
            IRS ITIN. Enter it in the entry space for the Social security number. If you
            do not have an ITIN, see <em>How to get a TIN</em> below.{" "}
          </p>{" "}
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            If you are a sole proprietor and you have an EIN, you may enter either
            your SSN or EIN.
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            {" "}
            If you are a single-member LLC that is disregarded as an entity
            separate from its owner, enter the owner’s SSN (or EIN, if the owner has
            one). If the LLC is classified as a corporation or partnership, enter the
            entity’s EIN.{" "}
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            {" "}
            Note: See <em>What Name and Number To Give the Requester</em>, later, for
            further clarification of name and TIN combinations.
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            {" "}
            <b>How to get a TIN.</b> If you do not have a TIN, apply for one immediately.
            To apply for an SSN, get Form SS-5, Application for a Social Security
            Card, from your local SSA office or get this form online at<a href="#" style={{ color: "#000", fontStyle: "italic" }}>
              www.SSA.gov</a>. You may also get this form by calling 800-772-1213. Use
            Form W-7, Application for IRS Individual Taxpayer Identification
            Number, to apply for an ITIN, or Form SS-4, Application for Employer
            Identification Number, to apply for an EIN. You can apply for an EIN
            online by accessing the IRS website at <a href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online" style={{ color: "#000", fontStyle: "italic" }}>www.irs.gov/EIN.</a> Go to
            <a href="https://www.irs.gov/forms-instructions" style={{ color: "#000", fontStyle: "italic" }}>www.irs.gov/Forms</a> to view, download, or print Form W-7 and/or Form
            SS-4. Or, you can go to <a href="https://www.irs.gov/forms-pubs/order-products" style={{ color: "#000", fontStyle: "italic" }}>www.irs.gov/OrderForms</a> to place an order and
            have Form W-7 and/or Form SS-4 mailed to you within 15 business
            days.{" "}
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            {" "}
            If you are asked to complete Form W-9 but do not have a TIN, apply
            for a TIN and enter “Applied For” in the space for the TIN, sign and date
            the form, and give it to the requester. For interest and dividend
            payments, and certain payments made with respect to readily tradable
            instruments, you will generally have 60 days to get a TIN and give it to
            the requester before you are subject to backup withholding on
            payments. The 60-day rule does not apply to other types of payments.
            You will be subject to backup withholding on all such payments until
            you provide your TIN to the requester.{" "}
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <b>Note:</b> Entering “Applied For” means that you have already applied for a
            TIN or that you intend to apply for one soon. See also Establishing U.S.
            status for purposes of chapter 3 and chapter 4 withholding, earlier, for
            when you may instead be subject to withholding under chapter 3 or 4 of
            the Code.{" "}
          </p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            {" "}
            <b>Caution</b>: A disregarded U.S. entity that has a foreign owner must use
            the appropriate Form W-8.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UpdatedForm4;