import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

export const getSignedUrlQaAudit = async (data) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/QASignedUrlFromS3`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/QASignedUrlFromS3`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify(data)
        })
        return await response.data
    } catch (error) {
		console.log('derror', error.response);
        // return {
        //     error: (error.response && error.response.data.error) || 'Something went wrong!'
        // }
		let errMsg = (error.response && error.response.data.error);
	    if( error && error.response && error.response.status == 500){
	        errMsg = (error.response.data.error && error.response.data.error.error.error)
	    }
		return {
			error: (errMsg) || "An error has occured."
			//error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
    }
}

export const processUploadedFileQaAudit = async (FileID) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/QAUploadFileData`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/QAUploadFileData`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                FileID
            })
        })
        return await response.data
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const getFileUploadHistoryQaAudit = async (PageNo, PageSize, SortColumn, SortOrder) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/QAFileList`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/QAFileList`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                PageNo: PageNo || 1,
                PageSize: PageSize || 10,
                SortColumn: SortColumn || "",
                SortOrder: SortOrder || "",
            })
        })
        return await response.data
    } catch (error) {
        console.log("error res", error);
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const approveRejectFileQaAudit = async (FileRecordID, Status) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/ApproveRejectQAFile`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/ApproveRejectQAFile`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                FileID: FileRecordID,
                Status: Status
            })
        })
        return await response.data
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const getFileRewardsQaAudit = async (FileRecordID) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/TotalQARecordsByPrmotionId`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/TotalQARecordsByPrmotionId`,
            method: 'POST',
            data: JSON.stringify({
                filerecordid: FileRecordID
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        return await response.data
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const getFileValidationStatusQaAudit = async (FileRecordID) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/QAValidationStatus`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/QAValidationStatus`,
            method: 'POST',
            data: JSON.stringify({
                filerecordid: FileRecordID
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            }
        })
        return await response.data
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const getFileErrorByStatusQaAudit = async (FileRecordID, StatusId) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/QAFileErrorData`,
            url: `${config.base_url}/api/QaReports/QAFileErrorData`,
            method: 'POST',
            data: JSON.stringify({
                filerecordid: FileRecordID,
                statusid: StatusId
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            }
        })
        return await response.data
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const getFileStatusQaAudit = async (FileID) => {
    const accessToken = await getAccessToken()
    try {
        const response = await axios({
            url: `${config.base_url}/api/QaReports/QADetailsByID`,
            //url: `https://04fydzg394.execute-api.us-east-2.amazonaws.com/uat/QADetailsByID`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                FileID
            })
        })
        return await response.data.data[0]
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!'
        }
    }
}

export const downloadSampleFileQaAudit = async () => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/getSignedUrlForSweepStakeTemplate`,
            //url: `https://qj1h5xu8mj.execute-api.us-east-2.amazonaws.com/dev/getSignedUrlForSweepStakeTemplate`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        return {
            error: null,
            data: responseBody.PreSignedURL || null
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: null
        }
    }
}