import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import moment from "moment-timezone";
import "./styles.scss";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1)
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getActiveSteps(steps) {
    return -1;
    //return steps && steps.filter(step => step.RewardIndicator === "completed").length;
}

export default function CustomizedSteppers(props) {
    const classes = useStyles();
    const steps = props.data || [];
    const paymentMethodID = props.paymentMethodID || "";
    const isExpired = props.data.filter((item, index)=> {
        if (item.RewardStatusID==14 && item.RewardIndicator==='completed') {
            return props.data[index-1].RewardIndicator==='disabled'?true:false;
        }
    }).length;

    const tz = moment.tz(moment.tz.guess()).zoneAbbr();

    return (
        <div className={`${classes.root} stepperWrapper`}>
            <Stepper alternativeLabel activeStep={getActiveSteps(steps)}>
                {
                steps.map((step, i) => {
                    const labelProps = {};
                    let tipmsg = "";
                    if (step.RewardIndicator === "error") {
                        labelProps.error = true;
                    }

                    if(paymentMethodID ==2 && ( step.RewardStatusID== 12 )){
                        tipmsg = step.RewardIndicator==='error' ? <Tooltip title={"Return:"+(step.ReturnErrorCode ||"") + ":"+ (step.ReturnErrorDescription || "")}><InfoRoundedIcon color="error" /></Tooltip> :null;
                    }
                    if(paymentMethodID ==3 && (step.RewardStatusID== 12 && (step.ReturnErrorCode || step.ReturnErrorDescription))){
                        const errCode = step.ReturnErrorCode ? (":"+step.ReturnErrorCode) :"";
                        const errDesc = step.ReturnErrorDescription ? (":"+step.ReturnErrorDescription) :"";
                        tipmsg = step.RewardStatusID== 12 ? <Tooltip title={"Error"+ errCode + errDesc}><InfoRoundedIcon color="error" /></Tooltip> :null;
                    } else {
                        if(step.RewardStatusID== 11 && step.ReturnErrorCode && step.ReturnErrorDescription){
                            tipmsg = step.RewardStatusID== 11 ? <Tooltip title={"NOC:"+ (step.ReturnErrorCode||"") + ":"+ (step.ReturnErrorDescription || "")}><InfoRoundedIcon color="error" /></Tooltip> :null;
                        }
                    }

                    return (<Step key={i} data={(step.RewardStatusID==4 && isExpired )? "first":"other"} disabled={step.RewardIndicator === "disabled"} completed={step.RewardIndicator === "completed"} style={step.RewardIndicator === "disabled" ? { opacity: "0.5" } : null}>
                        <StepLabel {...labelProps}>{step.RewardStatus}</StepLabel>
                        <div className="datetimelabel">
                            <div className="date">{step.OtherDetails ? moment.utc(step.OtherDetails).local().format("M/DD/YYYY hh:mm A ") + tz : ""} {tipmsg} </div>
                        </div>
                    </Step>)
                })}
            </Stepper>
        </div>
    );
}