const initialState = {
    reports:{
        list:[],
        error: null,
        yearList:null,
        selectedYear:null,
    }
}

export default function reports(state = initialState, action = {}) {
	switch (action.type) {
        case 'REPORT_LIST_FETCH_START':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    list: [],
                    error: null,
                }
            }
		case 'REPORT_LIST_FETCH_SUCCESS':
			return {
                ...state,
                reports: {
                    ...state.reports,
                    list: action.payload,
                    error: null,
                }
            }
        case 'REPORT_LIST_FETCH_FAILED':
			return {
                ...state,
                reports: {
                    ...state.reports,
                    error: action.payload
                }
            }
        case 'REPORT_YEAR_LIST_FETCH_SUCCESS':
			return {
                ...state,
                reports: {
                    ...state.reports,
                    yearList: action.payload,
                    selectedYear:action.currentYear,
                    error: null,
                }
            }
        case 'REPORT_YEAR_LIST_FETCH_FAILED':
			return {
                ...state,
                reports: {
                    ...state.reports,
                    error: action.payload
                }
            }
		default:
			return {
				...state
			}
	}
}