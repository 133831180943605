import React, { Component, Fragment } from 'react'
import { MenuItem, Icon, InputAdornment, OutlinedInput, CircularProgress, TextField, Container, Paper, Button, Grid, Box, Table, TableRow, TableBody, TablePagination, TableCell, withStyles, TableSortLabel } from '@material-ui/core'
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import DatePicker from "react-datepicker";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { Link } from "react-router-dom";
import { fetchSupportList, fetchCategoryList, fetchStatusList } from '~/redux/actions/support';
import Notification from '~/components/Notification';
import './styles.scss';

const styles = theme => ({
    filterBg: {
        backgroundColor: '#f0f5f7',
        width: '100%'
    },
    inputBg: {
        backgroundColor: '#fff',
        padding: '0.5rem'
    }
})

class SupportListView extends Component {

    state = {
        isLoading: true,
        fetchingList: false,
        showFilter: false,
        page: 0,
        rowsPerPage: 10,
        filterOpen: false,
        TicketNo: '',
        Status: "",
        Subject: "",
        OEID: "",
        RaisedBy: "",
        CreatedDate: null,
        Category: "",
        categoryList: null,
        statusList: null,
        SortColumn: "",
        SortOrder: ""
    }

    filterCliCkFun = () => {
        this.setState({
            filterOpen: !this.state.filterOpen
        })
    }

    clearFilter = () => {
        this.setState({
            TicketNo: '',
            Status: "",
            Subject: "",
            OEID: "",
            RaisedBy: "",
            CreatedDate: null,
            Category: "",
        }, () => this.getSupportList())
    }

    handlePageChange = (event, page) => {
        const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
        this.setState({
            page
        }, () => this.getSupportList(SortColumn, SortOrder))
    }

    handleRowsPerPageChange = (event) => {
        const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }, () => this.getSupportList(SortColumn, SortOrder))
    }

    handleSorting(SortColumn) {
        const { SortOrder } = this.state;
        let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
        this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, fetchingList: true }, () => {
            this.getSupportList(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
        });
    }

    getSupportList = (SortColumn, SortOrder) => {
        const { TicketNo, Status, Subject, OEID, RaisedBy, CreatedDate, Category, page, rowsPerPage } = this.state;
        console.log(page, rowsPerPage)
        this.setState({
            fetchingList: true
        }, () => {
            this.props.dispatch(fetchSupportList({ TicketNo, Status, Subject, OEID, RaisedBy, CreatedDate, Category, PageNo: page + 1, PageSize: rowsPerPage, SortColumn: SortColumn || "", SortOrder: SortOrder || "" })).then(() => {
                this.setState({
                    isLoading: false,
                    fetchingList: false
                })
            })
        })
    }

    getCategoryList = () => {
        this.props.dispatch(fetchCategoryList()).then((response) => {
            //set state here on success
            if (!response)
                return false;
            this.setState({
                categoryList: response
            })
        })
    }

    getStatusList = () => {
        this.props.dispatch(fetchStatusList()).then((response) => {
            //set state here on success
            if (!response)
                return false;
            this.setState({
                statusList: response
            })
        })
    }

    supportDetail = (item) => {
        const { claims } = this.props;
        if (claims && claims.includes("support_viewstatus")) {
            this.props.history.push('/support/status', { supportDetail: item });
        }
    }

    componentDidMount() {
        this.getSupportList();
        this.getCategoryList();
        this.getStatusList();
    }

    render() {
        const { filterOpen, isLoading, categoryList, statusList, fetchingList, TicketNo, Status, Subject, OEID, RaisedBy, CreatedDate, Category, page, rowsPerPage, SortColumn, SortOrder } = this.state;
        const { classes, user, support, claims } = this.props;
        console.log(claims);
        if (isLoading) {
            return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
        }
        return (
            <Container id="support-list-view">
                <Grid container >
                    <Grid item xs={12}>
                        <Box pt={3} display="flex" mt={2} mb={2} justifyContent="flex-end" alignItems="center" >
                            <img className="filter-button" onClick={this.filterCliCkFun} src={require('~/assets/images/filter-icon.png')} alt="filter-icon" />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container={filterOpen} hidden className="filterWrap">
                    <Box display="flex">
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <Box mx={1}>
                                    <label className="inputLabel">Ticket No.</label>
                                    <TextField
                                        fullWidth={true}
                                        autoComplete="off"
                                        variant="outlined"
                                        value={TicketNo}
                                        onChange={event => this.setState({ TicketNo: event.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box mx={1}>
                                    <label className="inputLabel">Status</label>
                                    <TextField
                                        fullWidth={true}
                                        select
                                        value={Status}
                                        autoComplete="off"
                                        variant="outlined"
                                        onChange={event => this.setState({ Status: event.target.value })}
                                        inputProps={{
                                            ref: el => this.Status = el
                                        }}
                                    >
                                        {statusList ? statusList.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        )) :
                                            (
                                                <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                            )
                                        }
                                    </TextField>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box mx={1}>
                                    <label className="inputLabel">Subject</label>
                                    <TextField
                                        fullWidth={true}
                                        autoComplete="off"
                                        variant="outlined"
                                        value={Subject}
                                        onChange={event => this.setState({ Subject: event.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box mx={1}>
                                    <label className="inputLabel">OEID</label>
                                    <TextField
                                        fullWidth={true}
                                        autoComplete="off"
                                        variant="outlined"
                                        value={OEID}
                                        onChange={event => this.setState({ OEID: event.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={3}>
                                    <Box mx={1}>
                                        <label className="inputLabel">Raised By</label>
                                        <TextField
                                            fullWidth={true}
                                            autoComplete="off"
                                            variant="outlined"
                                            value={RaisedBy}
                                            onChange={event => this.setState({ RaisedBy: event.target.value })}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box mx={1}>
                                        <label className="inputLabel">Date</label>
                                        <DatePicker
                                            customInput={<OutlinedInput
                                                variant="outlined"
                                                className="full-width"
                                                endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                                            />}
                                            className="datePicdesign dateField"
                                            selected={CreatedDate}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            onChange={CreatedDate => this.setState({ CreatedDate })}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box mx={1}>
                                        <label className="inputLabel">Category</label>
                                        <TextField
                                            fullWidth={true}
                                            select
                                            value={Category}
                                            autoComplete="off"
                                            variant="outlined"
                                            onChange={event => this.setState({ Category: event.target.value })}
                                            inputProps={{
                                                ref: el => this.Category = el
                                            }}
                                        >
                                            {categoryList ? categoryList.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            )) :
                                                (
                                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                                )
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box mx={1} display="flex" justifyContent="flex-end" alignItems="center">
                                        <Link to="#" style={{ marginTop: '30px' }}> <Button onClick={this.clearFilter} variant="outlined" color="primary" className="clearBtn">Clear</Button> </Link>
                                        {fetchingList ? (
                                            <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                        ) : (
                                                <Link to="#" style={{ marginTop: '30px' }}> <Button onClick={this.getSupportList} variant="contained" color="primary">Search</Button> </Link>
                                            )}
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>

                <Grid item style={{overflowX: 'auto'}}>
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "TicketNo"}
                                        direction={SortColumn === "TicketNo" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("TicketNo")}
                                    >
                                        Ticket No.
                                         {SortColumn === "TicketNo" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "Status"}
                                        direction={SortColumn === "Status" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("Status")}
                                    >
                                        Status
                                         {SortColumn === "Status" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "Subject"}
                                        direction={SortColumn === "Subject" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("Subject")}
                                    >
                                        Subject
                                         {SortColumn === "Subject" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "OEID"}
                                        direction={SortColumn === "OEID" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("OEID")}
                                    >
                                        OEID
                                         {SortColumn === "OEID" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "RaisedBy"}
                                        direction={SortColumn === "RaisedBy" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("RaisedBy")}
                                    >
                                        Raised By
                                         {SortColumn === "RaisedBy" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "Date"}
                                        direction={SortColumn === "Date" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("Date")}
                                    >
                                        Date
                                         {SortColumn === "Date" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "Category"}
                                        direction={SortColumn === "Category" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("Category")}
                                    >
                                        Category
                                         {SortColumn === "Category" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {fetchingList ? (
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                    support.list && support.list.map((item, index) => (
                                        <Fragment key={item.TicketNo}>
                                            <StyledTableRow onClick={e => this.supportDetail(item)}>
                                                <StyledTableCell>{item.TicketNo}</StyledTableCell>
                                                <StyledTableCell>{item.Status}</StyledTableCell>
                                                <StyledTableCell>{item.Subject}</StyledTableCell>
                                                <StyledTableCell>{item.OEID}</StyledTableCell>
                                                <StyledTableCell>{item.RaisedBy}</StyledTableCell>
                                                <StyledTableCell>{item.Date ? moment.utc(item.Date).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
                                                <StyledTableCell>{item.Category}</StyledTableCell>
                                            </StyledTableRow>
                                        </Fragment>
                                    ))
                                )}
                        </TableBody>
                        <StyledTableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={7}
                                    count={support.totalCount || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={this.handlePageChange}
                                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                                />
                            </TableRow>
                        </StyledTableFooter>
                    </Table>
                </Grid>
                {support.error && this.renderSnackbar(support.error)}
            </Container>
        )
    }

    renderSnackbar = message => {
        return <Notification variant="error" message={message} />
    }
}

export default connect(state => ({ ...state.user, ...state.support }))(withStyles(styles)(SupportListView));