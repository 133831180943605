import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom';
import TaxationListView from './ListView';
import "./styles.scss";

class AuthRoute extends Component {
    isAllowed(claims, name) {
        let permissions = claims;
        let str = `${name && name.toLowerCase()}`;
        let isEnabled = permissions && permissions.includes(str);
        if (isEnabled) {
            return true
        }
        return false;
    }
    render() {
        const { component: Component, name, claims, ...rest } = this.props;
        let isAccessable = this.isAllowed(claims, name);
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} claims={claims} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

class Taxation extends Component {
    render() {
        const { claims } = this.props;
        return (
            <div className="taxationWrapper">
                <Fragment>
                    <Switch>
                        <AuthRoute exact path='/taxation' component={TaxationListView} name={"w9form_view"} claims={claims} />
                    </Switch>
                </Fragment>
            </div>
        )
    }
}

export default Taxation;