import React from 'react';

const UpdatedForm2 = (props) => {
  return (
    <div id="page2">
      <div
        style={{
          clear: "both",
          width: "100%",
          fontSize: "7pt",
          paddingTop: "4px",
          paddingBottom: "4px",
          borderTop: "2px solid black",
          borderBottom: "2px solid black"
        }}
      >
        <span>Form W-9 (Rev. 3-2024)</span>
        <span style={{ marginLeft:"620px" }}>Page{" "}<span style={{ fontSize: "10pt", fontWeight: "bold"}}> 2</span></span>
      </div>
      
      <div style={{ clear: "both", width: "100%", fontSize: "8pt" }}>
        <div style={{ float: "left", width: "380px" }}>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            must obtain your correct taxpayer identification number (TIN), which
            may be your social security number (SSN), individual taxpayer
            identification number (ITIN), adoption taxpayer identification number
            (ATIN), or employer identification number (EIN), to report on an
            information return the amount paid to you, or other amount reportable
            on an information return. Examples of information returns include, but
            are not limited to, the following.
          </p>
          <ul style={{ margin: "4px 0px 4px 14px", padding: 0 }}>
            <li> Form 1099-INT (interest earned or paid).</li>
            <li>
              {" "}
              Form 1099-DIV (dividends, including those from stocks or
              mutual funds)
            </li>
            <li>
              {" "}
              Form 1099-MISC (various types of income, prizes, awards, or
              gross proceeds)
            </li>
            <li>
              {" "}
              Form 1099-NEC (nonemployee compensation).
            </li>
            <li>
              {" "}
              Form 1099-B (stock or mutual fund sales and certain other
              transactions by brokers)
            </li>
            <li> Form 1099-S (proceeds from real estate transactions)</li>
            <li>
              {" "}
              Form 1099-K (merchant card and third-party network
              transactions)
            </li>
            <li>
              {" "}
              Form 1098 (home mortgage interest), 1098-E (student loan
              interest), and 1098-T (tuition)
            </li>
            <li> Form 1099-C (canceled debt)</li>
            <li>
              {" "}
              Form 1099-A (acquisition or abandonment of secured property)
            </li>
          </ul>
          <p>  Use Form W-9 only if you are a U.S. person (including a
            resident alien), to provide your correct TIN.{" "}</p>
          <p><b>Caution:</b> If you don’t return Form W-9 to the requester with a TIN, you
            might be subject to backup withholding. See <em>What is backup
              withholding,</em> later.</p>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <b>By signing the filled-out form,</b> you:{" "}
          </p>
          <ol style={{ margin: "4px 0px 4px 14px", padding: 0 }}>
            <li>
              {" "}
              Certify that the TIN you are giving is correct (or you are
              waiting for a number to be issued);
            </li>
            <li>
              {" "}
              Certify that you are not subject to backup withholding; or
            </li>
            <li>
              {" "}
              Claim exemption from backup withholding if you are a U.S. exempt
              payee; and{" "}
            </li>
            <li>
              {" "}
              Certify to your non-foreign status for purposes of withholding under
              chapter 3 or 4 of the Code (if applicable); and
            </li>
            <li>
              {" "}
              Certify that FATCA code(s) entered on this form (if any) indicating
              that you are exempt from the FATCA reporting is correct. See <em>What Is
                FATCA Reporting,</em> later, for further information.
            </li>
          </ol>
          <p>
            {" "}
            <b> Note:</b> If you are a U.S. person and a requester gives
            you a form other than Form W-9 to request your TIN, you must
            use the requester’s form if it is substantially similar to
            this Form W-9.
          </p>
          <p>
            {" "}
            <b> Definition of a U.S. person.</b> For federal tax purposes,
            you are considered a U.S. person if you are:
          </p>
          <ul style={{ margin: "4px 0px 4px 14px", padding: 0 }}>
            <li>
              An individual who is a U.S. citizen or U.S. resident alien;{" "}
            </li>
            <li>
              {" "}
              A partnership, corporation, company, or association created
              or organized in the United States or under the laws of the
              United States;
            </li>
            <li> An estate (other than a foreign estate); or</li>
            <li>
              {" "}
              A domestic trust (as defined in Regulations section
              301.7701-7).{" "}
            </li>
          </ul>
          <p>
            {" "}
            <b> Establishing U.S. status for purposes of chapter 3 and chapter 4
              withholding.</b>  Payments made to foreign persons, including certain
            distributions, allocations of income, or transfers of sales proceeds, may
            be subject to withholding under chapter 3 or chapter 4 of the Code
            (sections 1441–1474). Under those rules, if a Form W-9 or other
            certification of non-foreign status has not been received, a withholding
            agent, transferee, or partnership (payor) generally applies presumption
            rules that may require the payor to withhold applicable tax from the
            recipient, owner, transferor, or partner (payee). See Pub. 515,
            Withholding of Tax on Nonresident Aliens and Foreign Entities.
          </p>
          <p>
            {" "}
            The following persons must provide Form W-9 to the payor for
            purposes of establishing its non-foreign status.
          </p>
          <ul style={{ margin: "4px 0px 4px 14px", padding: 0 }}>
            <li>
              {" "}
              In the case of a disregarded entity with a U.S. owner, the U.S. owner
              of the disregarded entity and not the disregarded entity.
            </li>
            <li>
              {" "}
              In the case of a grantor trust with a U.S. grantor or other U.S. owner,
              generally, the U.S. grantor or other U.S. owner of the grantor trust and
              not the grantor trust.
            </li>
            <li>
              {" "}
              In the case of a U.S. trust (other than a grantor trust), the U.S. trust
              and not the beneficiaries of the trust.

            </li>
          </ul>
          <p>See Pub. 515 for more information on providing a Form W-9 or a
            certification of non-foreign status to avoid withholding.</p>
        </div>
        <div
          style={{ float: "left", width: "380px", marginLeft: "20px" }}
        >
          <p>
            {" "}
            <b> Foreign person.</b> If you are a foreign person or the U.S. branch of a
            foreign bank that has elected to be treated as a U.S. person (under
            Regulations section 1.1441-1(b)(2)(iv) or other applicable section for
            chapter 3 or 4 purposes), do not use Form W-9. Instead, use the
            appropriate Form W-8 or Form 8233 (see Pub. 515). If you are a
            qualified foreign pension fund under Regulations section 1.897(l)-1(d), or
            a partnership that is wholly owned by qualified foreign pension funds,
            that is treated as a non-foreign person for purposes of section 1445
            withholding, do not use Form W-9. Instead, use Form W-8EXP (or other
            certification of non-foreign status).
          </p>

          <p>
            {" "}
            <b> Nonresident alien who becomes a resident alien. </b>{" "}
            Generally, only a
            nonresident alien individual may use the terms of a tax treaty to reduce
            or eliminate U.S. tax on certain types of income. However, most tax
            treaties contain a provision known as a saving clause. Exceptions
            specified in the saving clause may permit an exemption from tax to
            continue for certain types of income even after the payee has otherwise
            become a U.S. resident alien for tax purposes.
          </p>
          <p>
            {" "}
            If you are a U.S. resident alien who is relying on an exception
            contained in the saving clause of a tax treaty to claim an exemption
            from U.S. tax on certain types of income, you must attach a statement
            to Form W-9 that specifies the following five items.
          </p>
          <ol style={{ margin: "4px 0px 4px 14px", padding: 0 }}>
            <li>
              {" "}
              The treaty country. Generally, this must be the same treaty under
              which you claimed exemption from tax as a nonresident alien.
            </li>
            <li> The treaty article addressing the income.</li>
            <li>
              {" "}
              The article number (or location) in the tax treaty that
              contains the saving clause and its exceptions.
            </li>
            <li>
              {" "}
              The type and amount of income that qualifies for the
              exemption from tax.
            </li>
            <li>
              {" "}
              Sufficient facts to justify the exemption from tax under the
              terms of the treaty article.
            </li>
          </ol>
          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            <em>
              {" "}
              <b> Example. </b>{" "}
            </em>
            Article 20 of the U.S.-China income tax treaty allows an
            exemption from tax for scholarship income received by a
            Chinese student temporarily present in the United States.
            Under U.S. law, this student will become a resident alien for
            tax purposes if their stay in the United States exceeds 5
            calendar years. However, paragraph 2 of the first Protocol to
            the U.S.-China treaty (dated April 30, 1984) allows the
            provisions of Article 20 to continue to apply even after the
            Chinese student becomes a resident alien of the United States.
            A Chinese student who qualifies for this exception (under
            paragraph 2 of the first Protocol) and is relying on this
            exception to claim an exemption from tax on their
            scholarship or fellowship income would attach to Form W-9 a
            statement that includes the information described above to
            support that exemption.
          </p>
          <p>
            {" "}
            If you are a nonresident alien or a foreign entity, give the
            requester the appropriate completed Form W-8 or Form 8233.
          </p>
          <p
            style={{
              marginTop: "4px",
              marginBottom: "0px",
              fontSize: "13pt",
              fontWeight: "bold"
            }}
          >
            Backup Withholding
          </p>

          <p>
            {" "}
            <b> What is backup withholding? </b>Persons making certain
            payments to you must under certain conditions withhold and pay
            to the IRS 24% of such payments. This is called “backup
            withholding.” Payments that may be subject to backup
            withholding include, but are not limited to, interest, tax-exempt interest, dividends,
            broker and barter exchange transactions, rents, royalties,
            nonemployee pay, payments made in settlement of payment card
            and third-party network transactions, and certain payments
            from fishing boat operators. Real estate transactions are not
            subject to backup withholding.{" "}
          </p>
          <p>
            You will not be subject to backup withholding on payments you
            receive if you give the requester your correct TIN, make the
            proper certifications, and report all your taxable interest
            and dividends on your tax return.
          </p>
          <p>
            {" "}
            <b>
              {" "}
              Payments you receive will be subject to backup withholding
              if:{" "}
            </b>{" "}
          </p>
          <ol
            style={{
              margin: "2px 0px 0px 10px",
              padding: "0px",
              lineHeight: "15px"
            }}
          >
            <li>You do not furnish your TIN to the requester; </li>
            <li>
              {" "}
              You do not certify your TIN when required (see the
              instructions for Part II for details);
            </li>
            <li>
              {" "}
              The IRS tells the requester that you furnished an incorrect
              TIN;
            </li>
            <li>
              {" "}
              The IRS tells you that you are subject to backup withholding
              because you did not report all your interest and dividends
              on your tax return (for reportable interest and dividends
              only); or
            </li>
            <li>
              {" "}
              You do not certify to the requester that you are not subject
              to backup withholding , as described in item 4 under “By signing the filledout form” above (for reportable interest
              and dividend accounts opened after 1983 only).
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default UpdatedForm2;