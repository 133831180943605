import React from "react";
import { Grid, Paper, Button, CircularProgress, Box } from "@material-ui/core";
import "./style.scss";
import ToggleSwitch from "../../../components/Switch";
import { Link } from "react-router-dom";
import { AlertDialog } from "../../../components/Dialogs";
import { getUserPermission, setUserPermissions } from "~/redux/helpers/permissions.js";
import { connect } from "react-redux";

const style = {
  paper: {
    padding: "41px 64px 99px 54px",
    color: "#3f3f3f",
    margin: "26px 0"
  }
};

class UserPermissions extends React.Component {
  state = { open: false, permissions: [], btnSpinner: false, hasChanged: false };

  componentDidMount = async () => {
    const { userId } = this.props.match.params;
    let data = { clientId: 1, userId: parseInt(userId) };
    const response = await getUserPermission(data)
    if (!response.ErrorMessage) {
      this.setState({ permissions: response["FinalDataList"] });
    } else {
      alert(response.ErrorMessage);
    }
  }

  isPagePermissionPresent = (pageId, permissionId) => {
    const { outgoing } = this.state;
    let isEntryPresent = false;
    outgoing.forEach(entry => {
      if (entry.pageId === pageId && entry.RightId === permissionId) {
        isEntryPresent = true;
      }
    });
    return isEntryPresent;
  }
  isGroupPermissionPresent = (groupId, permissionId) => {
    const { outgoing } = this.state;
    let isEntryPresent = false;
    outgoing.forEach(entry => {
      if (entry.GroupId === groupId && entry.RightId === permissionId) {
        isEntryPresent = true;
      }
    });
    return isEntryPresent;
  }
  _switchGroup(permission, groupId, pageId, flag) {
    const { permissions } = this.state;
    permissions.forEach((group, i) => {
      if (group.GroupId === groupId) {
        group && group.permissions && group.permissions.forEach((p, j) => {
          if (p.PermissionId === permission.PermissionId) {
            p.IsActive = !p.IsActive;
            this.setState({ ...this.state });
          } else {
            p.IsActive = flag;
            this.setState({ ...this.state });
          }
        })
      }
    });
  }
  _switchPage(permission, pageId, groupId, flag) {
    const { permissions } = this.state;
    permissions.forEach((group, i) => {
      if (group.GroupId === groupId) {
        permissions[i] && permissions[i].Pages && permissions[i].Pages.forEach((page, j) => {
          if (page.PageId === pageId) {
            page && page.permissions && page.permissions.forEach(p => {
              if (p.PermissionId === permission.PermissionId) {
                p.IsActive = !p.IsActive;
                this.setState({ ...this.state });
              } else {
                p.IsActive = flag;
                this.setState({ ...this.state });
              }
            })
          }
        })
      }
    });
  }

  handlePageSwitch = (permission, pageId, groupId) => {
    if (permission.Permission && permission.Permission === "View" && permission.IsActive) {
      this.setState({ hasChanged: true }, () => {
        this._switchPage(permission, pageId, groupId, false);
      })
    } else if (permission.Permission && permission.Permission === "Download" && !permission.IsActive) {
      this.setState({ hasChanged: true }, () => {
        this._switchPage(permission, pageId, groupId, true);
      })
    } else {
      permission.IsActive = !permission.IsActive;
    }
    this.setState({ ...this.state, hasChanged: true });
  }

  handleGroupSwitch = (permission, groupId, pageId) => {
    if (permission.Permission && permission.Permission === "View" && permission.IsActive) {
      this.setState({ hasChanged: true }, () => {
        this._switchGroup(permission, groupId, pageId, false);
      })
    } else if (permission.Permission && (permission.Permission === "Create/Edit" || permission.Permission === "Upload Fulfilment" || permission.Permission === "Close Ticket") && !permission.IsActive) {
      this.setState({ hasChanged: true }, () => {
        this._switchGroup(permission, groupId, pageId, true);
      })
    } else {
      permission.IsActive = !permission.IsActive;
    }
    this.setState({ ...this.state, hasChanged: true });
  }
  handleViewToggle = (permission) => {
    permission.view = !permission.view;
    this.setState({ ...this.state });
  }
  handleDownloadToggle = (permission) => {
    permission.download = !permission.download;
    this.setState({ ...this.state });
  }
  savePermissions = () => {
    this.setState({ isLoading: true, btnSpinner: true }, () => {
      const { userId } = this.props.match.params;
      //const { outgoing } = this.state;
      let outgoing = [];
      const { permissions } = this.state;
      permissions.forEach(group => {
        group.permissions.forEach(permission => {
          outgoing.push({ ClientId: 1, GroupId: group.GroupId, PageId: permission.PageId || 0, RightId: permission.PermissionId, HasRight: permission.IsActive, UserId: userId });
        });
        group && group.Pages && group.Pages.forEach(page => {
          page.permissions.forEach(permission => {
            outgoing.push({ ClientId: 1, GroupId: group.GroupId, PageId: page.PageId, RightId: permission.PermissionId, HasRight: permission.IsActive, UserId: userId });
          })
        })
      });
      setUserPermissions(outgoing).then(response => {
        if (response && !response.ErrorMessage) {
          this.setState({ isLoading: false, open: true })
        } else {
          alert("Something went wrong.")
        }
        this.setState({ btnSpinner: false });
      }).catch(err => {
        this.setState({ btnSpinner: false });
        alert(err);
      })
    })
  }

  render() {
    const { open } = this.state;
    const { permissions, btnSpinner, hasChanged } = this.state;
    return (
      <Grid container justify="center" id="">
        <Grid item xs={11} className="settingsWrapper">
          <h3 className="heading">User Permission</h3>
          <Paper style={style.paper}>
            {permissions && permissions.length > 0 ?
              <div>
                {permissions && permissions.map((group, i) => (
                  <div key={i}>
                    <Grid container spacing={3} className="rectangle" key={group.GroupId}>
                      <Grid item xs={3}>
                        <span className="rectangleTitle">{group.Name}</span>
                      </Grid>
                      {group.permissions && group.permissions.map(permission =>
                        <Grid item key={permission.PermissionId} xs={3}>
                          <div className="switch">
                            <span className="switchTitle">{permission.Permission}</span>
                            <ToggleSwitch active={permission.IsActive} handleToggle={() => this.handleGroupSwitch(permission, group.GroupId)} />
                          </div>
                        </Grid>)}
                    </Grid>

                    {group.Pages && group.Pages.length > 0 ?
                      <div className="expandedView">
                        <Box
                          p={2}
                          justifyContent="center"
                          alignItems="center">
                          {group.Pages && group.Pages.map(page => (
                            <div className="expandedRow" key={page.PageId}>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  <span className="expandTitle">{page.Name}</span>
                                </Grid>
                                {page.permissions && page.permissions.map(permission => (
                                  <Grid item key={permission.PermissionId} xs={3}>
                                    <div className="switch">
                                      <span className="switchTitle">{permission.Permission}</span>
                                      <ToggleSwitch active={permission.IsActive} handleToggle={() => this.handlePageSwitch(permission, page.PageId, group.GroupId)} />
                                    </div>
                                  </Grid>))}
                              </Grid>
                            </div>))}
                        </Box>
                      </div>
                      : null}
                  </div>
                ))}

                <Grid>
                  <div className="controls">
                    <Button variant="contained" color="secondary" className="btns">
                      <Link to="/settings">Cancel</Link>
                    </Button>
                    {!btnSpinner ?
                      <Button
                        disabled={!hasChanged}
                        variant="contained"
                        color="primary"
                        className="btns"
                        onClick={this.savePermissions}
                      >
                        Save
                      </Button> :
                      <Box display="inline" justifyContent="center" alignItems="center">
                        <CircularProgress color="primary" style={{ margin: "0px 40px", position: "absolute" }} />
                      </Box>}
                  </div>
                </Grid>
              </div> : <CircularProgress />}
          </Paper>
        </Grid>
        <AlertDialog
          open={open}
          title={""}
          message={"User permissions updated."}
          onConfirm={() => {
            this.setState({ open: false });
            this.props.history.push("/settings");
          }}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(UserPermissions);
