
import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user';

export const fetchRewardsOverviewBarData = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/RewardsRedeemRewardsOverview`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/RewardsRedeemRewardsOverview`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        return {
            error: null,
            data: responseBody.Data
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: []
        }
    }
}

export const fetchRewardProgressData = ({ lRewardID, lRewardType }) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/RewardsTrackingRedeemRewards`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/RewardsTrackingRedeemRewards`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lRewardID: lRewardID || 0,
                lRewardType: lRewardType || 'RW',
            })
        })
        const responseBody = await response.data;
        return {
            error: null,
            data: responseBody.Data
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: []
        }
    }
}

export const reinitiatePayment = async (RewardID) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/ReinitiateRewards`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/ReinitiateRewards`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lRewardID: RewardID || 0
            })
        })
        const responseBody = await response.data;
        return {
            error: null,
            data: responseBody
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: []
        }
    }
} 

export const GetPaymentMethods = async () => {
	const accessToken = await getAccessToken()
	//const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwb3J0YnUiOiIzNzI1MSIsIm9laWQiOiIxOTk5OSIsImlhdCI6MTU3NDQxNTMzN30.fO07D0wn-I5NHH_WobI9s830_gQrLriSM8N65ZMO4fs'
	try{
		const response = await axios({
			url: `${config.base_url}/api/Redeem/GetPaymentMethod`,
			//url: `https://g8gsgwy2x0.execute-api.us-east-2.amazonaws.com/dev/GetPaymentMethod`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			}
		})
        console.log("redeem awards respon", response);
		return await response.data
	}catch(error){
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
	// return [{paymentTypeId:2, paymentMethodName: "Bank Account", masked_AccountNumber:"******2345", default:true},
	// {paymentTypeId:3, paymentMethodName: "Zelle", masked_AccountNumber: "", default:false}]
}