import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

export const getSignedUrl = async (data) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getSignedUrl`,
			//url: `https://24sdmxag6g.execute-api.us-east-1.amazonaws.com/develop/getSignedUrl`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify(data)
		})
		return await response.data
	} catch (error) {
	    let errMsg = (error.response && error.response.data.error);
	    if( error && error.response && error.response.status == 500){
	        errMsg = (error.response.data.error && error.response.data.error.error.error)
	    }
		return {
			error: (errMsg) || "An error has occured."
		}
	}
}

export const processUploadedFile = async (FileID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Rewards/rewarduploadfiledata`,
			//url: `https://1ryw9njki8.execute-api.us-east-2.amazonaws.com/Dev/rewarduploadfiledata`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				FileID
			})
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileUploadHistory = async (PageNo, PageSize, SortColumn, SortOrder) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getRewardFileHistory`,
			//url: `https://24sdmxag6g.execute-api.us-east-1.amazonaws.com/develop/getRewardFileHistory`,
			//url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/getRewardFileHistory`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				PageNo: PageNo || 1,
				PageSize: PageSize || 10,
				SortColumn: SortColumn || "",
				SortOrder: SortOrder || "",
			})
		})
		return await response.data
	} catch (error) {
		console.log("error res", error);
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const approveRejectFile = async (FileRecordID, Status) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Rewards/approverejectrewardfile`,
			//url: `https://1ryw9njki8.execute-api.us-east-2.amazonaws.com/Dev/approverejectrewardfile`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				FileID: FileRecordID,
				Status: Status
			})
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileRewards = async (FileRecordID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/totalRewardsByPrmotionID`,
			//url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/totalRewardsByPrmotionID`,
			method: 'POST',
			data: JSON.stringify({
				filerecordid: FileRecordID
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileValidationStatus = async (FileRecordID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/rewardValidationStatus`,
			//url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/rewardValidationStatus`,
			method: 'POST',
			data: JSON.stringify({
				filerecordid: FileRecordID
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			}
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileErrorByStatus = async (FileRecordID, StatusId) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			//url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/rewardsFileErrorData`,
			url: `${config.base_url}/api/Reward/rewardsFileErrorData`,
			method: 'POST',
			data: JSON.stringify({
				filerecordid: FileRecordID,
				statusid: StatusId
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			}
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileStatus = async (FileID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getRewardDetailsByID`,
			//url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/getRewardDetailsByID`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				FileID
			})
		})
		return await response.data.data[0]
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const downloadSampleFile = async () => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/getSignedUrlForRewardDownloadTemplate`,
            //url: `https://qj1h5xu8mj.execute-api.us-east-2.amazonaws.com/dev/getSignedUrlForRewardDownloadTemplate`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        console.log("download file", responseBody);
        return {
            error: null,
            data: responseBody.PreSignedURL || null
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: null
        }
    }
}