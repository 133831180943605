import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx'
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	}
}))


export default function Notifier(props) {
    const classes = useStyles()
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });

    const { open, handleClose, message } = props;
    const { vertical, horizontal } = state;

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                className={clsx(classes[props.variant])}
                variant="error"
                key={`${vertical},${horizontal}`}
                open={open}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{message}</span>}
            />
        </div>
    );
}