import React, { Fragment } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell
} from "@material-ui/core";
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter
} from "~/components/StyledTable";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "../../../components/Dialogs";
import ToggleSwitch from "../../../components/Switch";

const roles = {
  1: "Client",
  2: "Promotion Team",
  3: "AEs/Client Services",
  4: "Super Admin"
}

export default class NotificationManagementList extends React.Component {
  state = {
    isLoading: false,
    fetchingList: false,
    page: 0,
    rowsPerPage: 10,
    clientId: 1,
    open: false
  };

  componentDidMount() {
    // this.updateUsersList();
  }

  handlePageChange = (event, page) => {
    this.setState(
      {
        page
      },
      () => this.updateUsersList()
    );
  };

  handleRowsPerPageChange = event => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, 10)
      },
      () => this.updateUsersList()
    );
  };

  updateUsersList = () => {
    const { clientId, page, rowsPerPage } = this.state;
    this.setState(
      {
        fetchingList: true
      },
      () => {
        this.props
          .fetchUsersList({
            clientId,
            pageSize: rowsPerPage,
            pageNo: page + 1
          })
          .then(() => {
            this.setState({
              isLoading: false,
              fetchingList: false
            });
          });
      }
    );
  };

  formatContactNumber = (number) => {
    return number && JSON.stringify(number).replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
  }

  render() {
    const { open, isLoading, page, rowsPerPage } = this.state;
    const { users } = this.props.users;
    const { list, totalCount } = users;
    console.log(users);
    return (
      <div>
        <Grid item xs={12}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>User Type</StyledTableCell>
                <StyledTableCell>Edit</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      display="flex"
                      p={5}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                  list.map((user, index) => (
                    <Fragment key={user.Id}>
                      <StyledTableRow>
                        <StyledTableCell>{`${user.FirstName} ${user.LastName}`}</StyledTableCell>
                        <StyledTableCell>{user.EmailAddress}</StyledTableCell>
                        <StyledTableCell>{roles[user.UserTypeId]}</StyledTableCell>
                        <StyledTableCell>
                          {user.userType !== "Super Admin" ? (
                            <Link
                              to={{
                                pathname: `/settings/editUser/${user.Id}/notification`,
                                query: {
                                  firstName: user.FirstName,
                                  lastName: user.LastName,
                                  contactNumber: user.PhoneNumber,
                                  userType: user.UserTypeId,
                                  email: user.EmailAddress,
                                  password: user.password,
                                  UserName: user.userName,
                                  source: "notificationManagement"
                                }
                              }}
                            >
                              <span className={"oval"}>
                                <img src={require('~/assets/images/edit.png')} alt="Edit User" />
                              </span>
                            </Link>
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell>
                          {user.userType !== "Super Admin" ? (
                            <div className="actions">
                              <ToggleSwitch />
                              <span
                                className={"oval"}
                                onClick={() => {
                                  this.setState({ open: true });
                                }}
                              >
                                <img src={require('~/assets/images/delete.png')} alt="delete" />
                              </span>
                            </div>
                          ) : null}
                        </StyledTableCell>
                      </StyledTableRow>
                      <TableRow></TableRow>
                    </Fragment>
                  ))
                )}
            </TableBody>
            <StyledTableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={8}
                  count={totalCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handleRowsPerPageChange}
                />
              </TableRow>
            </StyledTableFooter>
          </Table>
        </Grid>
        <ConfirmDialog
          open={open}
          title={""}
          message={"Are you sure you want to delete user profile?"}
          onCancel={() => {
            this.setState({ open: false });
          }}
          onConfirm={() => {
            this.setState({ open: false });
          }}
        />
      </div>
    );
  }
}