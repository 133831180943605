import React from 'react';

const UpdatedForm6 = (props) => {

  return (
    <div id="page6">
      <div
        style={{
          clear: "both",
          width: "100%",
          fontSize: "7pt",
          paddingTop: "4px",
          paddingBottom: "4px",
          borderTop: "2px solid black",
          borderBottom: "2px solid black"
        }}
      >
        <span>Form W-9 (Rev. 3-2024)</span>
        <span style={{ marginLeft: "620px" }}>Page{" "}<span style={{ fontSize: "10pt", fontWeight: "bold" }}> 6</span></span>
      </div>
      <div style={{ clear: "both", width: "100%", fontSize: "8pt" }}>
        <div style={{ float: "left", width: "380px" }}>
          <p>
            {" "}
            Victims of identity theft who are experiencing economic harm or a
            systemic problem, or are seeking help in resolving tax problems that
            have not been resolved through normal channels, may be eligible for
            Taxpayer Advocate Service (TAS) assistance. You can reach TAS by
            calling the TAS toll-free case intake line at 877-777-4778 or TTY/TDD
            800-829-4059.
          </p>
          <p>
            {" "}
            <b>
              {" "}
              Protect yourself from suspicious emails or phishing schemes.
              <br />
            </b>
            Phishing is the creation and use of email and websites designed to
            mimic legitimate business emails and websites. The most common act
            is sending an email to a user falsely claiming to be an established
            legitimate enterprise in an attempt to scam the user into surrendering
            private information that will be used for identity theft.
          </p>
          <p>
            The IRS does not initiate contacts with taxpayers via emails. Also, the
            IRS does not request personal detailed information through email or ask
            taxpayers for the PIN numbers, passwords, or similar secret access
            information for their credit card, bank, or other financial accounts.
          </p>

          <p style={{ marginTop: "2px", marginBottom: "0px" }}>
            If you receive an unsolicited email claiming to be from the IRS,
            forward this message to <em>phishing@irs.gov</em>. You may also report misuse
            of the IRS name, logo, or other IRS property to the Treasury Inspector
            General for Tax Administration (TIGTA) at 800-366-4484. You can
            forward suspicious emails to the Federal Trade Commission at
            <em>spam@uce.gov</em> or report them at <a href="https://reportfraud.ftc.gov/" style={{ color: "#000", fontStyle: "italic" }}>www.ftc.gov/complaint</a>. You can
            contact the FTC at <a href="https://consumer.ftc.gov/features/identity-theft" style={{ color: "#000", fontStyle: "italic" }}>www.ftc.gov/idtheft</a> or 877-IDTHEFT (877-438-4338).
            If you have been the victim of identity theft, see <a href="https://www.identitytheft.gov/" style={{ color: "#000", fontStyle: "italic" }}>www.IdentityTheft.gov</a>
            and Pub. 5027.
          </p>
          <p style={{ marginTop: "5px", marginBottom: "0px" }}>
            Go to <a href="https://www.irs.gov/identity-theft-central" style={{ color: "#000", fontStyle: "italic" }}>www.irs.gov/IdentityTheft</a> to learn more about identity theft and
            how to reduce your risk.
          </p>
        </div>
        <div
          style={{ float: "left", width: "380px", marginLeft: "20px" }}
        >
          <p
            style={{
              marginTop: "7px",
              marginBottom: "0px",
              fontWeight: "bold",
              fontSize: "13pt"
            }}
          >
            Privacy Act Notice
          </p>

          <p style={{ marginTop: "7px", marginBottom: "0px" }}>
            Section 6109 of the Internal Revenue Code requires you to provide your
            correct TIN to persons (including federal agencies) who are required to
            file information returns with the IRS to report interest, dividends, or
            certain other income paid to you; mortgage interest you paid; the
            acquisition or abandonment of secured property; the cancellation of
            debt; or contributions you made to an IRA, Archer MSA, or HSA. The
            person collecting this form uses the information on the form to file
            information returns with the IRS, reporting the above information.
            Routine uses of this information include giving it to the Department of
            Justice for civil and criminal litigation and to cities, states, the District of
            Columbia, and U.S. commonwealths and territories for use in
            administering their laws. The information may also be disclosed to other
            countries under a treaty, to federal and state agencies to enforce civil
            and criminal laws, or to federal law enforcement and intelligence
            agencies to combat terrorism. You must provide your TIN whether or not
            you are required to file a tax return. Under section 3406, payors must
            generally withhold a percentage of taxable interest, dividends, and
            certain other payments to a payee who does not give a TIN to the payor.
            Certain penalties may also apply for providing false or fraudulent
            information.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UpdatedForm6;