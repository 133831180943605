import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'
import currency from 'currency.js';
import moment from "moment";

export const fetchRewardsList = ({ RewardID, PortBu, PortfolioName, LocationCode, OfficeId, OfficeName, Rewards, RewardType, RewardStatus, CreatedDate, ExpirationDate, PromotionID, PageNo, PageSize, SortColumn, SortOrder, AlternateReward, PaymentMethod }) => async (dispatch) => {

    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Rewards/getrewardslist`,
            //url: `https://ebvw43n2m1.execute-api.us-east-2.amazonaws.com/dev/getrewardslist`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                RewardID: RewardID || "",
                PortBu: PortBu || "",
                PortfolioName: PortfolioName || "",
                LocationCode: LocationCode || "",
                OfficeId: OfficeId || "",
                OfficeName: OfficeName || "",
                RewardCurrency: "",
                Rewards: Rewards === "" ? "" : currency(Rewards).value,
                RewardType: RewardType || "",
                RewardStatus: RewardStatus || "",
                CreatedDate: CreatedDate ? moment(CreatedDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
                // ExpirationDate: ExpirationDate === null ? undefined : ExpirationDate,
                RewardToDate: ExpirationDate ? moment(ExpirationDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
                PromotionID: PromotionID || "",
                PageNo: PageNo || 1,
                PageSize: PageSize || 10,
                RewardCurrency: "",
                AlternateReward: AlternateReward || "",
                PaymentMethod: PaymentMethod || "",
                SortColumn: SortColumn || "",
                SortOrder: SortOrder || ""
            })
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'REWARDS_LIST_FETCH_SUCCESS',
                payload: responseBody.lstpromotion,
                totalCount: responseBody.TotalCount
            })
            return true
        }
        dispatch({
            type: 'REWARDS_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'REWARDS_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}
export const fetchStatusList = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/RewardStatus`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/RewardStatus`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'REWARD_STATUS_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return responseBody.Data
        }
        dispatch({
            type: 'REWARD_STATUS_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'REWARD_STATUS_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const removeReward = ({rewardId, username}) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Rewards/DeleteRewardsDetails`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/DeleteRewardsDetails`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lRewardID: rewardId || 0,
                lUserName: username || '',
            })
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'REMOVE_REWARD_SUCCESS',
                payload: {rewardId:rewardId}
            })
            return true;
        }
        dispatch({
            type: 'REMOVE_REWARD_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false;
    } catch (error) {
        dispatch({
            type: 'REMOVE_REWARD_FAILED',
            payload: error.message || "An error has occured."
        })
        return false;
    }
}

export const fetchRewardTypeList = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/GetRewardType`,
            //url: `https://boeeaptso4.execute-api.us-east-2.amazonaws.com/dev/GetRewardType`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'REWARD_TYPE_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return responseBody.Data
        }
        dispatch({
            type: 'REWARD_TYPE_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'REWARD_TYPE_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}