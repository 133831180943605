import React, {Fragment} from 'react';
import {
  Modal,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Box,
  withStyles,
  makeStyles,
  DialogTitle, Dialog, DialogActions, DialogContent, IconButton 
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import './styles.scss';

import LeftPagination from "./LeftPagination/";
import Form1 from "./Forms/Form1";
import Form2 from "./Forms/Form2";
import Form3 from "./Forms/Form3";
import Form4 from "./Forms/Form4";
import Form5 from "./Forms/Form5";
import Form6 from "./Forms/Form6";
import UpdatedForm1 from "./Forms/NewForms/UpdatedForm1";
import UpdatedForm2 from "./Forms/NewForms/UpdatedForm2";
import UpdatedForm3 from "./Forms/NewForms/UpdatedForm3";
import UpdatedForm4 from "./Forms/NewForms/UpdatedForm4";
import UpdatedForm5 from "./Forms/NewForms/UpdatedForm5";
import UpdatedForm6 from "./Forms/NewForms/UpdatedForm6";

const SwitchedComponent = (props) => {
console.log("form ", props.page);
    let Output; // save the rendered JSX to return

    // check the type of the component given in props
    switch ( props.page ) {
        //render form1 with props
        case 1:
          Output = (<Form1 { ...props } />);
          break;
        //render form2 with props
        case 2:
            Output = (<Form2 { ...props } />);
            break;
        case 3:
            Output = (<Form3 { ...props } />);
            break;
        case 4:
            Output = (<Form4 { ...props } />);
            break;
        case 5:
            Output = (<Form5 { ...props } />);
            break;
        case 6:
            Output = (<Form6 { ...props } />);
            break;
      default:
          Output = (<Form1 { ...props } />);
        break;
  }

  return Output; // return the output created in switch/case

};

const SwitchedComponentForUpdatedForm = (props) => {
    console.log("form ", props.page);
    let Output; // save the rendered JSX to return

    // check the type of the component given in props
    switch (props.page) {
        //render form1 with props
        case 1:
            Output = (<UpdatedForm1 {...props} />);
            break;
        //render form2 with props
        case 2:
            Output = (<UpdatedForm2 {...props} />);
            break;
        case 3:
            Output = (<UpdatedForm3 {...props} />);
            break;
        case 4:
            Output = (<UpdatedForm4 {...props} />);
            break;
        case 5:
            Output = (<UpdatedForm5 {...props} />);
            break;
        case 6:
            Output = (<UpdatedForm6 {...props} />);
            break;
        default:
            Output = (<UpdatedForm1 {...props} />);
            break;
    }

    return Output; // return the output created in switch/case

};

const TaxForm = (props) => {
    const {title, px, py, onConfirm, clickOutsideToClose, onClose, canSubmit, canEditSSN, formData,
     processingView, processingEdit, processingDraft, processingSubmit, handleChange, 
     handleEdit, handleDraft, handleSubmit, handleCancel, formPageNo, handleFormPageChange, errors, isExistingUser} = props;
    return (
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="w9Form"
        >
            <Box display="flex" style={{position:"fixed", top:0, width:"100%", padding:".7rem 4.5rem", margin:"0 auto", backgroundColor:"#f9f9f9", zIndex:6, boxSizing: "border-box"}} >
                <Box flexGrow={1}>
                    <Button variant="contained" className="backBtn" color="primary" onClick={() => onClose()}>Back</Button>
                </Box>
                {canSubmit && <Box>
                    {processingEdit? (
                        null
                        ) : (
                            formData && formData["Year"] >= new Date().getFullYear() && <Button variant="contained" color="secondary" xs={6} style={{margin:"0 1rem"}} onClick={handleEdit} >Edit</Button>
                        )
                    }

                    {processingSubmit ? (
                        <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                        ) : (
                            processingEdit && <Button variant="contained" color="primary" xs={6} onClick={handleSubmit} >Submit</Button>
                        )
                    }
                </Box>
                }
            </Box>
            <Box py={py || 6} px={px || 6}>
                <DialogContent className="alert-dialog-message">
                    {processingView ? (
                        <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    ):(
                        <div style={{ display: "flex" }} id="taxation-form">
                            <LeftPagination 
                                selectedPage={formPageNo}
                                handleFormPageChange={(pageNo)=> handleFormPageChange(pageNo)}
                            />
                            <div
                              style={{
                                width: "780px",
                                background: "#fff",
                                padding: "50px",
                                fontFamily: "Arial, Helvetica, sans-serif",
                                margin: "0 auto"
                              }}
                            >
                                    {isExistingUser ?
                                        <SwitchedComponent canEdit={processingEdit} canEditSSN={canEditSSN} page={formPageNo} errors={errors} formData={formData} handleChange={handleChange} />
                                        :
                                        <SwitchedComponentForUpdatedForm canEdit={processingEdit} canEditSSN={canEditSSN} page={formPageNo} errors={errors} formData={formData} handleChange={handleChange} />
                                    }
                              </div>
                        </div>
                )}
                </DialogContent>
            </Box>
        </Dialog>
    );
}
export default TaxForm;