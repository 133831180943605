import axios from 'axios';
import config from '~/config';
import { getAccessToken } from '~/redux/helpers/user';

export const fetchSSODetails = async () => {
  try {
    const accessToken = await getAccessToken();
    const response = await axios({
      //url: `http://localhost:8091/api/Sso/ssoLogin`,
      url: `${config.base_url}/api/Sso/ssoLogin`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
        pragma: 'no-cache',
      }
    });
    const responseBody = await response.data;
    return responseBody;
  } catch (error) {
    return {
      message:
        (error.response && error.response.data.message),
      data: {},
      error: true,
    };
  }
};