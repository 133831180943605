var initialState = {
	promotion: {
		list: [],
		details: {},
        fetchListError: null,
        fetchDetailError: null,
        createError: null,
        updateError: null,
        totalCount:0,
        fundsOverview:[],
        promotionRewards:[],
        promotionDetailOverview:null,
        statusList:null,
        error:null,
	},
}

export default function promotion(state = initialState, action = {}) {
	switch (action.type) {
		case 'PROMOTION_LIST_FETCH_SUCCESS':
			return {
				...state,
				promotion: {
					...state.promotion,
                    list: action.payload,
                    totalCount:action.totalCount,
					fetchListError: null,
				}
			}
		case 'PROMOTION_LIST_FETCH_FAILED':
			return {
				...state,
				promotion: {
					...state.promotion,
					fetchListError: action.payload.error
				}
            }

		case 'PROMOTION_DETAIL_FETCH_SUCCESS':
			return {
				...state,
				promotion: {
					...state.promotion,
					details: {
						...action.payload,
					},
                    updateError: null,
                    fetchDetailError: null
				}
			}
		case 'PROMOTION_DETAIL_FETCH_FAILED':
			return {
				...state,
				promotion: {
					...state.promotion,
					details: {},
                    updateError: null,
					fetchDetailError: action.payload
				}
            }
        case 'CREATE_PROMOTION_START':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {},
                    createError: null,
                }
            }
        case 'CREATE_PROMOTION_SUCCESS':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {
                        ...action.payload,
                    },
                    createError: null
                }
            }
        case 'CREATE_PROMOTION_FAILED':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {},
                    createError: action.payload
                }
            }

        case 'UPDATE_PROMOTION_START':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {},
                    updateError: null,
                    fetchDetailError: null
                }
            }
        case 'UPDATE_PROMOTION_SUCCESS':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {
                        ...action.payload,
                    },
                    updateError: null,
                    fetchDetailError: null
                }
            }
        case 'UPDATE_PROMOTION_FAILED':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {},
                    updateError: action.payload,
                    fetchDetailError: null
                }
            }

        case 'DELETE_PROMOTION_START':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {},
                    updateError: null,
                    fetchDetailError: null
                }
            }
        case 'DELETE_PROMOTION_SUCCESS':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    details: {},
                    updateError: null,
                    fetchDetailError: null
                }
            }
        case 'DELETE_PROMOTION_FAILED':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    updateError: action.payload,
                    fetchDetailError: null
                }
            }
        case 'FUNDS_OVERVIEW_START':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    fundsOverview: [],
                    error: null
                }
            }
        case 'FUNDS_OVERVIEW_FETCH_SUCCESS':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    fundsOverview: action.payload,
                    error: null
                }
            }
        case 'FUNDS_OVERVIEW_FETCH_FAILED':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    fundsOverview: [],
                    error: action.payload
                }
            }
        case 'PROMOTION_REWARDS_FETCH_SUCCESS':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    promotionRewards: action.payload,
                    error: null
                }
            }
        case 'PROMOTION_REWARDS_FETCH_FAILED':
            return {
                ...state,
                promotion: {
                    ...state.promotion,
                    promotionRewards: [],
                    error: action.payload
                }
            }
            case 'PROMOTION_DETAIL_OVERVIEW_FETCH_SUCCESS':
			return {
				...state,
				promotion: {
					...state.promotion,
					promotionDetailOverview: {
						...action.payload,
					},
                    error: null,
				}
			}
		case 'PROMOTION_DETAIL_OVERVIEW_FETCH_FAILED':
			return {
				...state,
				promotion: {
					...state.promotion,
					promotionDetailOverview: null,
                    error: null,
				}
            }
        case 'PROMOTION_STATUS_LIST_FETCH_SUCCESS':
			return {
                ...state,
                promotion: {
                    ...state.promotion,
                    statusList: action.payload,
                    error: null,
                }
            }
        case 'PROMOTION_STATUS_LIST_FETCH_FAILED':
			return {
                ...state,
                promotion: {
                    ...state.promotion,
                    error: action.payload.error
                }
            }
		default:
			return {
				...state
			}
	}
}