import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

const cookies = new Cookies(window.document.cookie)

export const userInfo = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/OAuth/oauth/self`,
				//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/self`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data
			if (!responseBody.error) {
                console.log("self api response success");
				dispatch({
					type: 'LOGIN_SUCCESS',
					payload: {
						...responseBody.user
					}
				})
				return true
			}
		}
		return logout()
	} catch (error) {
		cookies.remove('refreshToken')
		cookies.remove('accessToken')
		dispatch({
			type: 'LOGIN_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const getUserDetails = (Id) => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/GetUserDetails`,
				//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/GetUserDetails`,
				method: 'POST',
				data: JSON.stringify({
					Id: Id
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data
			if (!responseBody.error) {
                console.log("self api response success");
				dispatch({
					type: 'LOGIN_SUCCESS',
					payload: {
						...responseBody.user
					}
				})
				return true
			}
		}
		return logout()
	} catch (error) {
		cookies.remove('refreshToken')
		cookies.remove('accessToken')
		dispatch({
			type: 'LOGIN_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}


export const login = (credentials) => async (dispatch) => {
	try {
		let cookies = new Cookies(window.document.cookie)
		const response = await axios({
			url: `${config.base_url}/api/OAuth/oauth/login`,
			//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/login`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(credentials)
		})
		const responseBody = await response.data
		if (!responseBody.error) {
			const { accessToken, refreshToken } = responseBody
			cookies.set('accessToken', accessToken, { path: '/' })
			cookies.set('refreshToken', refreshToken, { path: '/' })
			dispatch({
				type: 'LOGIN_SUCCESS',
				payload: {
					...responseBody.user
				}
			});
			return true;
		} else {
			dispatch({
				type: 'LOGIN_FAILED',
				payload: {
					error: responseBody.error || 'Oops! Something went wrong.'
				}
			});
			return false;
		}
	} catch (error) {
        let errMsg = (error.response && error.response.data.error);
        console.log("Error response", error.response);
        //console.log("Error response data", error.response.data);
        //console.log("Error response data error ", error.response.data);
        if(error.response.status == 500){
            errMsg = (error.response.data.error && error.response.data.error.error.error)
        }
		dispatch({
			type: 'LOGIN_FAILED',
			payload: {
				error: (errMsg) || "An error has occured."
			}
		})
		return false
	}
}

export const setNewPassword = (credentials) => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		const response = await axios({
			url: `${config.base_url}/api/OAuth/oauth/updatePassword`,
			//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/updatePassword`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify(credentials)
		})
		const responseBody = await response.data
		if (!responseBody.error) {
			dispatch({
				type: 'UPDATE_PASSWORD_SUCCESS',
				payload: {
					PasswordChangedDate: new Date()
				}
			})
		} else {
			dispatch({
				type: 'UPDATE_PASSWORD_FAILED',
				payload: responseBody.error || 'Oops! Something went wrong.'
			})
		}
	} catch (error) {
		dispatch({
			type: 'UPDATE_PASSWORD_FAILED',
			payload: (error.response && error.response.data.error) || "An error has occured."
		})
	}
}

export const logout = () => {
	let cookies = new Cookies(window.document.cookie)
	cookies.remove('accessToken')
	cookies.remove('refreshToken')
	return {
		type: 'LOGOUT_SUCCESS',
		payload: {}
	}
}