import React, { Component, Fragment } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PromotionListView from './ListView'
import PromotionDetailView from './DetailView'
import PromotionUpdateView from './UpdateView'
import PromotionEditView from './EditView'

import './styles.scss'

class AuthRoute extends Component {

	isAllowed(claims, name) {
		let permissions = claims;
		let str = `${name && name.toLowerCase()}`;
		let isEnabled = permissions && permissions.includes(str);
        console.log("name",str);
		if (isEnabled) {
			return true
		}
		return false;
	}

	render() {
		const { component: Component, name, claims, ...rest } = this.props;
		let isAccessable = this.isAllowed(claims, name);
		return (
			<Route exact={true} {...rest} render={(props) => (
				(isAccessable === true) ?
					<Fragment>
						<Component {...props} />
					</Fragment>
					: null
			)} />
		)
	}
}

class Promotion extends Component {
	render() {
		const { claims } = this.props;
		console.log("log",claims)
		return (
			<Fragment>
				<Switch>
					<AuthRoute exact path='/promotion' component={PromotionListView} name={"promotion_view"} claims={claims} />
					<AuthRoute exact path='/promotion/:promotionId' component={PromotionUpdateView} name="promotion_create" claims={claims} />
					{
						// <Route exact path='/promotion/update/:promotionId' component={PromotionUpdateView} />
					}
				</Switch>
			</Fragment>
		)
	}
}

export default Promotion