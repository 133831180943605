var initialState = {
    configuration:{
        renewalDate: null,
        taxGroupDate: null,
        error: null,
    }
}

export default function configuration(state = initialState, action = {}) {
	switch (action.type) {
		case 'RENEWWALDATE_FETCH_SUCCESS':
			return {
                ...state,
                configuration: {
                    ...state.configuration,
                    renewalDate: action.payload,
                    error: null,
                }
            }
        case 'RENEWWALDATE_FETCH_FAILED':
			return {
                ...state,
                configuration: {
                    ...state.configuration,
                    error: action.payload
                }
            }
        case 'SAVE_RENEWWALDATE_SUCCESS':
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    renewalDate: action.payload,
                    error: null
                }
            }
        case 'SAVE_RENEWWALDATE_FAILED':
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    error: action.payload
                }
            }
        case 'SUBMISSIONDATE_FETCH_SUCCESS':
			return {
                ...state,
                configuration: {
                    ...state.configuration,
                    taxGroupDate: action.payload,
                    error: null,
                }
            }
        case 'SUBMISSIONDATE_FETCH_FAILED':
			return {
                ...state,
                configuration: {
                    ...state.configuration,
                    error: action.payload
                }
            }
        case 'SAVE_SUBMISSIONDATE_SUCCESS':
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    taxGroupDate: action.payload,
                    error: null
                }
            }
        case 'SAVE_SUBMISSIONDATE_FAILED':
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    error: action.payload
                }
            }
		default:
			return {
				...state
			}
	}
}