var initialState = {
    rewards: {
        list: [],
        details: {},
        fetchListError: null,
        fetchDetailError: null,
        createError: null,
        updateError: null,
        statusList:null,
        rewardTypeList:null,
        totalCount: 0
    },
}

export default function rewards(state = initialState, action = {}) {

    switch (action.type) {
        case 'REWARDS_LIST_FETCH_SUCCESS':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    list: action.payload,
                    totalCount: action.totalCount,
                    fetchListError: null,
                }
            }
        case 'REWARDS_LIST_FETCH_FAILED':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    fetchListError: action.payload.error
                }
            }

        case 'REWARDS_DETAIL_FETCH_SUCCESS':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {
                        ...action.payload,
                    },
                    fetchDetailError: null
                }
            }
        case 'REWARDS_DETAIL_FETCH_FAILED':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {},
                    fetchDetailError: action.payload
                }
            }
        case 'CREATE_REWARDS_START':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {},
                    createError: null,
                }
            }
        case 'CREATE_REWARDS_SUCCESS':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {
                        ...action.payload,
                    },
                    createError: null
                }
            }
        case 'CREATE_REWARDS_FAILED':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {},
                    createError: action.payload
                }
            }
        case 'UPDATE_REWARDS_SUCCESS':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {
                        ...action.payload,
                    },
                    updateError: null,
                    fetchDetailError: null
                }
            }
        case 'UPDATE_REWARDS_FAILED':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    details: {},
                    updateError: action.payload,
                    fetchDetailError: null
                }
            }
        case 'REWARD_STATUS_LIST_FETCH_SUCCESS':
			return {
                ...state,
                rewards: {
                    ...state.rewards,
                    statusList: action.payload,
                    error: null,
                }
            }
        case 'REWARD_STATUS_LIST_FETCH_FAILED':
			return {
                ...state,
                rewards: {
                    ...state.rewards,
                    error: action.payload.error
                }
            }
       case 'REMOVE_REWARD_SUCCESS':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    list:[...state.rewards.list.filter((item, i) => parseInt(item.RewardID) !== parseInt(action.payload.rewardId))],
                    totalCount:state.rewards.totalCount - 1,
                    error: null
                }
            };
        case 'REMOVE_REWARD_FAILED':
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    error: action.payload
                }
            };
        case 'REWARD_TYPE_LIST_FETCH_SUCCESS':
			return {
                ...state,
                rewards: {
                    ...state.rewards,
                    rewardTypeList: action.payload,
                    error: null,
                }
            }
        case 'REWARD_TYPE_LIST_FETCH_FAILED':
			return {
                ...state,
                rewards: {
                    ...state.rewards,
                    error: action.payload.error
                }
            }
        default:
            return {
                ...state
            }
    }
}