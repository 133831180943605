import { combineReducers } from 'redux'
import user from './user'
import promotion from './promotion'
import rewards from './rewards'
import support from './support';
import permissions from './permissions';
import users from './settings';
import reports from './reports';
import configuration from './configuration';
import taxations from './taxation';

const reducer = combineReducers({
	user,
	promotion,
	rewards,
	support,
	permissions,
	users,
    reports,
    configuration,
    taxations
})

export default reducer