import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

export const getSignedUrl = async (data) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getSignedUrlFromS3`,
			//url: `https://oc0mnvcr45.execute-api.us-east-2.amazonaws.com/dev/getSignedUrlFromS3`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify(data)
		})
		return await response.data
	} catch (error) {
	    let errMsg = (error.response && error.response.data.error);
	    if( error && error.response && error.response.status == 500){
	        errMsg = (error.response.data.error && error.response.data.error.error.error)
	    }
		return {
			error: (errMsg) || "An error has occured."
		}
	}
}

export const processUploadedFile = async (FileID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Rewards/sweepstakeuploadfiledata`,
			//url: `https://isktqux0na.execute-api.us-east-2.amazonaws.com/dev/sweepstakeuploadfiledata`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				FileID
			})
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileUploadHistory = async (PageNo, PageSize, SortColumn, SortOrder) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getSweepStakeFileList`,
			//url: `https://oc0mnvcr45.execute-api.us-east-2.amazonaws.com/dev/getSweepStakeFileList`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				PageNo: PageNo || 1,
				PageSize: PageSize || 10,
				SortColumn: SortColumn || "",
				SortOrder: SortOrder || "",
			})
		})
		return await response.data
	} catch (error) {
		console.log("error res", error);
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const approveRejectFile = async (FileRecordID, Status) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Rewards/approverejectrewardfile`,
			//url: `https://1ryw9njki8.execute-api.us-east-2.amazonaws.com/Dev/approverejectrewardfile`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				FileID: FileRecordID,
				Status: Status
			})
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileRewards = async (FileRecordID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getTotalSweepStakeByPrmotionId`,
			//url: `https://oc0mnvcr45.execute-api.us-east-2.amazonaws.com/dev/getTotalSweepStakeByPrmotionId`,
			method: 'POST',
			data: JSON.stringify({
				filerecordid: FileRecordID
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileValidationStatus = async (FileRecordID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getSweepStakeValidationStatus`,
			//url: `https://oc0mnvcr45.execute-api.us-east-2.amazonaws.com/dev/getSweepStakeValidationStatus`,
			method: 'POST',
			data: JSON.stringify({
				filerecordid: FileRecordID
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			}
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const getFileErrorByStatus = async (FileRecordID, StatusId) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			//url: `https://oc0mnvcr45.execute-api.us-east-2.amazonaws.com/dev/getSweepStakeFileErrorData`,
			url: `${config.base_url}/api/Reward/getSweepStakeFileErrorData`,
			method: 'POST',
			data: JSON.stringify({
				filerecordid: FileRecordID,
				statusid: StatusId
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			}
		})
		return await response.data
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
    }
}

export const getFileStatus = async (FileID) => {
	const accessToken = await getAccessToken()
	try {
		const response = await axios({
			url: `${config.base_url}/api/Reward/getSweepStakeDetailsByID`,
			//url: `https://oc0mnvcr45.execute-api.us-east-2.amazonaws.com/dev/getSweepStakeDetailsByID`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify({
				FileID
			})
		})
		return await response.data.data[0]
	} catch (error) {
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}

export const downloadSampleSweepstakeFile = async () => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Reward/getSignedUrlForSweepStakeTemplate`,
            //url: `https://qj1h5xu8mj.execute-api.us-east-2.amazonaws.com/dev/getSignedUrlForSweepStakeTemplate`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        console.log("download sweepstake sample file", responseBody);
        return {
            error: null,
            data: responseBody.PreSignedURL || null
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: null
        }
    }
}