var initialState = {
  users: {
    list: [],
    fetchListError: null,
    fetchDetailError: null,
    createError: null,
    updateError: null,
    totalCount: 0
  }
};

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case "USERS_LIST_FETCH_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          list: action.payload,
          totalCount: action.totalCount,
          fetchListError: null
        }
      };

    case "USERS_LIST_FETCH_FAILED":
      return {
        ...state,
        users: {
          ...state.users,
          fetchListError: action.payload.error
        }
      };

    case "CREATE_ADMIN_USER_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          list: [...state.users.list, action.payload],
          totalCount: state.users.totalCount + 1,
          fetchListError: null
        }
      };
    case "CREATE_ADMIN_USER_FAILED":
      return {
        ...state,
        users: {
          ...state.users,
          createError: action.payload
        }
      };

    case "UPDATE_USER_DETAILS_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          updateError: null
        }
      };

    case "UPDATE_USER_DETAILS_FAILED":
      return {
        ...state,
        users: {
          ...state.users,
          updateError: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
}