var initialState = {
    taxations: {
        list: [],
        error: null,
        totalCount: 0,
        yearList:null,
        selectedYear:null,
        statusList:[],
        taxationOverview:null,
        formData:null,
    },
}

export default function taxations(state = initialState, action = {}) {

    switch (action.type) {
        case 'TAXATIONS_LIST_FETCH_SUCCESS':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    list: action.payload,
                    totalCount: action.totalCount,
                    error: null,
                }
            }
        case 'TAXATIONS_LIST_FETCH_FAILED':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            }
        case 'TAXATIONS_OVERVIEW_FETCH_SUCCESS':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    taxationOverview: action.payload,
                    error: null,
                }
            }
        case 'TAXATIONS_OVERVIEW_FETCH_FAILED':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            }
        case 'TAXATION_DETAIL_FETCH_SUCCESS':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    details: {
                        ...action.payload,
                    },
                    error: null
                }
            }
        case 'TAXATION_DETAIL_FETCH_FAILED':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    details: {},
                    error: action.payload
                }
            }
        case 'TAXATION_STATUS_LIST_FETCH_SUCCESS':
			return {
                ...state,
                taxations: {
                    ...state.taxations,
                    statusList: action.payload,
                    error: null,
                }
            }
        case 'TAXATION_STATUS_LIST_FETCH_FAILED':
			return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            }
        case 'TAXATION_YEAR_LIST_FETCH_SUCCESS':
			return {
                ...state,
                taxations: {
                    ...state.taxations,
                    yearList: action.payload,
                    selectedYear:action.currentYear,
                    error: null,
                }
            }
        case 'TAXATION_YEAR_LIST_FETCH_FAILED':
			return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            }
       case 'TAXATION_REMOVE_SUCCESS':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    list:[...state.taxations.list.filter((item, i) => parseInt(item.RewardID) !== parseInt(action.payload.rewardId))],
                    totalCount:state.taxations.totalCount - 1,
                    error: null
                }
            };
        case 'TAXATIONS_REMOVE_FAILED':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            };
        case 'W9FORM_FETCH_SUCCESS':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    formData: action.payload,
                    error: null,
                }
            }
        case 'W9FORM_FETCH_FAILED':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            }
         case 'W9FORM_SAVE_SUCCESS':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    formData: action.payload,
                    error: null,
                }
            }
        case 'W9FORM_SAVE_FAILED':
            return {
                ...state,
                taxations: {
                    ...state.taxations,
                    error: action.payload
                }
            }
        default:
            return {
                ...state
            }
    }
}