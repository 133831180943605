import React, {Fragment} from 'react';
import {
  Modal,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  TableSortLabel,
  Box,
  withStyles,
  makeStyles,
  DialogTitle, Dialog, DialogActions, DialogContent, IconButton 
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter,
  StyledTableColRow,
  StyledTableColCell,
  StyledTableFooterReport
} from "~/components/StyledTable";
import moment from 'moment';
import './styles.scss';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function ReportViewer(props) {
    const {title, px, py, onConfirm, clickOutsideToClose, onClose, previewProgress, downloadProgress, items, page, rowsPerPage, handleDownload, handlePageChange, handleRowsPerPageChange,
    order, orderBy, onRequestSort, canDownload} = props;
    return (
        <>
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
                    <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
                <DialogContent className="alert-dialog-message">
                    {previewProgress ? (
                    <>
                    <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    </>
                    ):(
                    <Table id="report-list">
                        <StyledTableHead style={{fontWeight:'bold'}}>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={17}>Promotion Summary Report</StyledTableColCell>
                            </TableRow>
                            <StyledTableRow>
                            <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "PromotionId" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionId"}
                                      direction={orderBy === "PromotionId" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionId")}
                                    >
                                      Promotion Id
                                      {orderBy === "PromotionId" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "PromotionName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionName"}
                                      direction={orderBy === "PromotionName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionName")}
                                    >
                                      Promotion Name
                                      {orderBy === "PromotionName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "ExpiredFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpiredFundsAdded"}
                                      direction={orderBy === "ExpiredFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpiredFundsAdded")}
                                    >
                                      Expired Funds Added
                                      {orderBy === "ExpiredFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "NewFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "NewFundsAdded"}
                                      direction={orderBy === "NewFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "NewFundsAdded")}
                                    >
                                      New Funds Added
                                      {orderBy === "NewFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>  
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "AssignedFunds" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AssignedFunds"}
                                      direction={orderBy === "AssignedFunds" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AssignedFunds")}
                                    >
                                      Assigned Funds
                                      {orderBy === "AssignedFunds" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "AllocatedFund" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AllocatedFund"}
                                      direction={orderBy === "AllocatedFund" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AllocatedFund")}
                                    >
                                      Allocated Fund
                                      {orderBy === "AllocatedFund" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "RewardAvailable" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardAvailable"}
                                      direction={orderBy === "RewardAvailable" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardAvailable")}
                                    >
                                      Reward Available
                                      {orderBy === "RewardAvailable" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "Initiated" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Initiated"}
                                      direction={orderBy === "Initiated" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Initiated")}
                                    >
                                      Initiated
                                      {orderBy === "Initiated" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "PaymentInProgress" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentInProgress"}
                                      direction={orderBy === "PaymentInProgress" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentInProgress")}
                                    >
                                      Payment In Progress
                                      {orderBy === "PaymentInProgress" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "PaymentFailed" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentFailed"}
                                      direction={orderBy === "PaymentFailed" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentFailed")}
                                    >
                                      PaymentFailed
                                      {orderBy === "PaymentFailed" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "PaymentSettled" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentSettled"}
                                      direction={orderBy === "PaymentSettled" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentSettled")}
                                    >
                                      Payment Settled
                                      {orderBy === "PaymentSettled" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "Expired" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Expired"}
                                      direction={orderBy === "Expired" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Expired")}
                                    >
                                      Expired
                                      {orderBy === "Expired" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "FulfillmentDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "FulfillmentDate"}
                                      direction={orderBy === "FulfillmentDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "FulfillmentDate")}
                                    >
                                      Fulfillment Date
                                      {orderBy === "FulfillmentDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "ExpirationDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpirationDate"}
                                      direction={orderBy === "ExpirationDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpirationDate")}
                                    >
                                      Expiration Date
                                      {orderBy === "ExpirationDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "PortalStatusName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PortalStatusName"}
                                      direction={orderBy === "PortalStatusName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PortalStatusName")}
                                    >
                                      Portal Status Name
                                      {orderBy === "PortalStatusName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "CreatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "CreatedDate"}
                                      direction={orderBy === "CreatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "CreatedDate")}
                                    >
                                      Created Date
                                      {orderBy === "CreatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>  
                                <StyledTableColCell style={{textAlign:"center"}} sortDirection={orderBy === "UpdatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "UpdatedDate"}
                                      direction={orderBy === "UpdatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "UpdatedDate")}
                                    >
                                      Updated Date
                                      {orderBy === "UpdatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                            
                              
                          // items.length&&  console.log('itemsssssss',items),items
                              ( items || rowsPerPage > 0
                              ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items)
                                .map((item, index) => (
                                <Fragment key={index}>
                                    {/* <StyledTableRow>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromoId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromoName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.EmpEarnedIncentive}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.EmpRedeemedIncentive}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PerEmpRedeemedIncentive}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.TotalRedeemedIncentive}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PerRedeemedIncentive}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.DepositIncentive}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.Incentive_Exp_Date ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromoStatus}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.ExpiredFund}</StyledTableColCell>
                                    </StyledTableRow> */}
                                    <StyledTableRow>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.ExpiredFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.NewFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AssignedFunds}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AllocatedFund}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardAvailable}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.Initiated}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentInProgress}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentFailed}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentSettled}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.Expired}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.FulfillmentDate ? moment(item.FulfillmentDate).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.ExpirationDate ? moment(item.ExpirationDate).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PortalStatusName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.CreatedDate ? moment(item.CreatedDate).format("MM/DD/YYYY") : ""}</StyledTableColCell>  
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.UpdatedDate ? moment(item.UpdatedDate).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                    </StyledTableRow>
                                </Fragment>
                            ))
                            }
                        </TableBody>
                        <StyledTableFooterReport>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={17}
                                    count={items.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(event, page)=>handlePageChange(event, page)}
                                    onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
                                />
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={17} style={{justifyContent:"flex-end"}}>
                                {downloadProgress ? (
                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                    <div>{canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                            <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
                                        </Box>}
                                    </div>
                                    )}
                                </StyledTableCell>
                            </TableRow>   
                        </StyledTableFooterReport>
                    </Table>
                )}
                </DialogContent>
            </Box>
        </Dialog>

        </>
    );
}